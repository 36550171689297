import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, SvgIcon } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { FiberManualRecord } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AssetRatios } from '../../../../Assets/Icons/asset_ratios.svg';
import { ReactComponent as Close } from '../../../../Assets/Icons/close.svg';
import { ReactComponent as Download } from '../../../../Assets/Icons/cloud_download.svg';
import { ReactComponent as Checkmark } from '../../../../Assets/Icons/checkmark.svg';
import { FileDownloader } from '../../FileDownloader';
import { SERVER_ENDPOINT } from '../../../../environment';
import { assetGalleryStyles } from './AssetGallery.style';
import { useUnityAnalytics } from '../../../../Hooks/useUnityAnalytics';

const AssetPreviewThumbnail = props => {
    const { selected, index, asset, clickHandler } = props;
    const { type, ratio, srcUrl } = asset;
    const isVideo = type === 'video';
    const isImage = type === 'image';
    const isSquare = ratio === '1:1';

    return (
        <Box sx={assetGalleryStyles.assetPreviewContainer(selected)}>
            {isImage && (
                <Box
                    component='img'
                    sx={assetGalleryStyles.assetPreviewImage(isSquare)}
                    key={index}
                    src={srcUrl}
                    alt=''
                    onClick={() => clickHandler(index)}
                    onKeyDown={() => clickHandler(index)}
                />
            )}
            {isVideo && (
                <Box
                    component='video'
                    sx={assetGalleryStyles.assetPreviewImage(isSquare)}
                    key={index}
                    onClick={() => clickHandler(index)}>
                    <source src={srcUrl} type='video/mp4' />
                    <track src='' kind='captions' label='english_captions' />
                </Box>
            )}
        </Box>
    );
};

const AssetPreview = props => {
    const { asset } = props;
    const type = asset?.type;
    const srcUrl = asset?.srcUrl;
    const isVideo = type === 'video';
    const isImage = type === 'image';

    const videoRef = useRef();

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [srcUrl]);

    return (
        <>
            {isImage && <img src={srcUrl} alt='Selected' />}
            {isVideo && (
                <video autoPlay ref={videoRef} muted controls loop>
                    <source src={srcUrl} type='video/mp4' />
                </video>
            )}
        </>
    );
};

const momentContents = (previewAssets, t) => {
    let videos = 0;
    let images = 0;
    for (let index = 0; index < previewAssets.length; index++) {
        const { type } = previewAssets[index];
        switch (type) {
            case 'video':
                videos++;
                break;
            case 'image':
                images++;
                break;
            default:
                console.log('Unsupported asset type.');
                break;
        }
    }

    if (images === 0) {
        return t('moments.assets.only_video', { count: videos });
    } else if (videos === 0) {
        return t('moments.assets.only_image', { count: images });
    }

    return t('moments.assets.video_and_image', { imageCount: images, videoCount: videos });
};

export const AssetGallery = props => {
    const { authState } = useOktaAuth();
    const { recordIndividualMomentDownload } = useUnityAnalytics();
    const [modalReady, updateModalReady] = useState(false);
    const [assetPreviews, setAssetPreviews] = useState([]);
    const [selectedAsset, setSelectedAsset] = useState();
    const [description, setDescription] = useState();
    const [downloadAssetsURL, setDownloadAssetsURL] = useState('');
    const [isDownloaded, setIsDownloaded] = useState(false);
    const { momentId, index, handleClose } = props;

    const fileDownloader = useRef(null);

    const { t } = useTranslation();

    useEffect(() => {
        const getModalData = () => {
            const endpoint = `${SERVER_ENDPOINT}/moments/${momentId}`;
            fetch(endpoint, {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    setAssetPreviews(data.assets);
                    setDescription(data.description);
                    updateModalReady(true);
                })
                .catch(error => {
                    console.log(error);
                });
        };
        getModalData();
    }, [momentId, authState.accessToken.accessToken]);

    useEffect(() => {
        if (assetPreviews.length > 0) {
            setSelectedAsset(assetPreviews[0]);
        }
    }, [assetPreviews]);

    const handleNewAssetSelected = assetIndex => {
        setSelectedAsset(assetPreviews[assetIndex]);
    };

    const downloadAllAssets = () => {
        recordIndividualMomentDownload(momentId);
        const endpoint = `${SERVER_ENDPOINT}/moments/moment-url/${momentId}`;
        fetch(endpoint, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${authState.accessToken.accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setDownloadAssetsURL(data.momentURL);
            })
            .catch(error => {
                console.log(error);
            });
    };

    const handleToggleChange = (event, value) => {
        setSelectedAsset(value);
    };

    useEffect(() => {
        if (downloadAssetsURL) {
            fileDownloader.current.click();
            setIsDownloaded(true);
            setDownloadAssetsURL('');
        }
    }, [downloadAssetsURL]);

    // Swipe functions
    const SwipeTolerance = 10;
    const [touchStart, setTouchStart] = React.useState(0);
    const [touchEnd, setTouchEnd] = React.useState(0);

    const handleTouchStart = e => setTouchStart(e.targetTouches[0].clientX);
    const handleTouchMove = e => setTouchEnd(e.targetTouches[0].clientX);
    const handleTouchEnd = () => {
        const currentIndex = assetPreviews.indexOf(selectedAsset);
        if (touchStart - touchEnd > SwipeTolerance) {
            const Next = currentIndex + 1 >= assetPreviews.length ? currentIndex : currentIndex + 1; // End Scroll
            // const Next = (currentIndex+1>=assetPreviews.length)?0:currentIndex+1 // Loop scroll
            setSelectedAsset(assetPreviews[Next]);
        }
        if (touchStart - touchEnd < -SwipeTolerance) {
            const Prev = currentIndex - 1 < 0 ? currentIndex : currentIndex - 1; // End Scroll
            // const Prev = (currentIndex-1 < 0)?assetPreviews.length-1:currentIndex-1 // Loop scroll
            setSelectedAsset(assetPreviews[Prev]);
        }
    };

    return (
        <Box sx={assetGalleryStyles.container}>
            <>
                {modalReady && (
                    <Box sx={assetGalleryStyles.previewGallery}>
                        <Box sx={assetGalleryStyles.previewThumbnails}>
                            <Box sx={assetGalleryStyles.previewHeading}>{t('moments.previewHeading')}</Box>
                            <Box sx={assetGalleryStyles.imageContainer}>
                                {assetPreviews.map((assetPreview, mapIndex) => (
                                    <AssetPreviewThumbnail
                                        key={mapIndex}
                                        asset={assetPreview}
                                        selected={selectedAsset === assetPreview}
                                        index={mapIndex}
                                        clickHandler={handleNewAssetSelected}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={assetGalleryStyles.selected}
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}>
                            <AssetPreview momentId={momentId} asset={selectedAsset} />
                        </Box>
                        <Box sx={assetGalleryStyles.buttonGroup}>
                            <ToggleButtonGroup
                                orientation='horizontal'
                                value={selectedAsset}
                                exclusive
                                onChange={handleToggleChange}>
                                {assetPreviews.map(assetPreview => (
                                    <ToggleButton
                                        key={assetPreview.momentID}
                                        value={assetPreview}
                                        sx={assetGalleryStyles.buttonColor}>
                                        <FiberManualRecord sx={assetGalleryStyles.buttonSize} />
                                    </ToggleButton>
                                ))}
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={assetGalleryStyles.imageContent}>
                            <Box sx={assetGalleryStyles.heading}>
                                <Box sx={assetGalleryStyles.number}>#{index}</Box>
                                <Box sx={assetGalleryStyles.headingText}>{description}</Box>
                            </Box>
                            <Box sx={assetGalleryStyles.momentContent}>{momentContents(assetPreviews, t)}</Box>
                            <Box sx={assetGalleryStyles.momentSizes}>
                                <Box component={AssetRatios} />
                            </Box>
                            <Box sx={assetGalleryStyles.downloadButtonContainer}>
                                <Button
                                    variant='contained'
                                    onClick={downloadAllAssets}
                                    startIcon={
                                        <SvgIcon
                                            viewBox={isDownloaded ? '0 0 19 14' : '0 0 24 24'}
                                            component={isDownloaded ? Checkmark : Download}
                                            sx={assetGalleryStyles.downloadEndIcon}
                                        />
                                    }>
                                    {t('moments.download_all_btn_label')}
                                </Button>
                                <FileDownloader url={downloadAssetsURL} downloadRef={fileDownloader} />
                            </Box>
                        </Box>
                    </Box>
                )}
                <IconButton sx={assetGalleryStyles.closeButton} onClick={handleClose}>
                    <Close />
                </IconButton>
            </>
        </Box>
    );
};

export default AssetGallery;
