import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { VIMEO_FALLBACK_LINK } from '../../environment';
import { ReactComponent as NYSEMainLogo } from '../../Assets/Icons/NYSEMainLogo.svg';
import { loadingContainerStyles, LOADING_TRANSITION } from './LoadingContainer.style';
import { VimeoFallback } from '../VimeoFallback';

export const LoadingState = {
    Loading: 0,
    LoadingTransition: 1,
    Loaded: 2
};

// fallback to show livestream URL after 30 seconds waiting
const DISPLAY_FALLBACK_TIMEOUT = 30 * 1000;

export const LoadingContainer = ({ playerReady }) => {
    const [state, setState] = useState(LoadingState.Loading);
    const [showVimeoLink, setShowVimeoLink] = useState(false);

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            setShowVimeoLink(true);
        }, DISPLAY_FALLBACK_TIMEOUT);
        return () => {
            if (timeoutID) {
                clearTimeout(timeoutID);
            }
        };
    }, []);

    useEffect(() => {
        if (playerReady) {
            setState(LoadingState.LoadingTransition);
            setTimeout(() => setState(LoadingState.Loaded), LOADING_TRANSITION * 1000);
        }
    }, [playerReady]);

    if (state === LoadingState.Loaded) {
        return null;
    }

    return (
        <Box sx={loadingContainerStyles.loadingScrim(state === LoadingState.Loading)}>
            {showVimeoLink && VIMEO_FALLBACK_LINK ? (
                <VimeoFallback />
            ) : (
                <Box sx={loadingContainerStyles.logoRelativeContainer}>
                    <Box sx={loadingContainerStyles.ripplesContainer}>
                        <Box sx={loadingContainerStyles.rippleLoaderParent}>
                            <Box sx={loadingContainerStyles.rippleLoaderChild} />
                            <Box sx={{ ...loadingContainerStyles.rippleLoaderChild, animationDelay: '-0.5s' }} />
                        </Box>
                    </Box>
                    <NYSEMainLogo />
                </Box>
            )}
        </Box>
    );
};
