export const webGLAppStyles = {
    container: theme => ({
        overflow: 'hidden',
        display: 'flex',
        position: 'relative',
        height: '100%',
        width: '100%',
        bgcolor: 'black.main',
        [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
                background: theme.palette.primaryGradient.main
            }
        },
        [theme.breakpoints.down('xl')]: {
            '@media (orientation: landscape)': {
                height: 'var(--innerHeight, 100%)'
            }
        }
    })
};
