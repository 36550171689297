import React, { useState, useEffect, useRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import AdminForm from '../AdminForm';
import { USE_DEVELOPMENT, SERVER_ENDPOINT } from '../../../environment';
import { Typography } from '@mui/material';

const Admin = () => {
    const { authState, oktaAuth } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);

    const mutableAccessToken = useRef(authState.accessToken.accessToken);

    useEffect(() => {
        mutableAccessToken.current = authState.accessToken.accessToken;
    }, [authState.accessToken.accessToken]);

    // Okta - visitor logged in
    useEffect(() => {
        if (!authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then(info => {
                setUserInfo(info);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    // Settings - get list of admins
    const [settingsError, setSettingsError] = useState(null);
    const [settings, setSettings] = useState([]);
    const [debugSettings, setDebugSettings] = useState([]);

    // when USE_DEVELOPMENT, we need to load 2 different pieces: regular settings and debug settings!
    const totalPiecesToLoad = USE_DEVELOPMENT ? 2 : 1;
    const [piecesToLoad, setPiecesToLoad] = useState(totalPiecesToLoad);
    const updateLoadedSettingsCount = () => {
        setPiecesToLoad(count => count - 1);
    };

    useEffect(() => {
        // clear cache unless on the admin API page
        fetch(`${SERVER_ENDPOINT}/settings`, {
            headers: {
                authorization: `Bearer ${mutableAccessToken.current}`
            }
        })
            .then(res => res.json())
            .then(
                result => {
                    updateLoadedSettingsCount();
                    if (result.error) {
                        setSettingsError(result);
                    } else {
                        setSettings(result);
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                error => {
                    console.log(error);
                    updateLoadedSettingsCount();
                    setSettingsError(error);
                }
            );
    }, []); // runs once

    useEffect(() => {
        if (USE_DEVELOPMENT) {
            // clear cache unless on the admin API page
            fetch(`${SERVER_ENDPOINT}/debugSettings`, {
                headers: {
                    authorization: `Bearer ${mutableAccessToken.current}`
                }
            })
                .then(res => res.json())
                .then(
                    result => {
                        updateLoadedSettingsCount();
                        if (result.error) {
                            setSettingsError(result);
                        } else {
                            setDebugSettings(result);
                        }
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    error => {
                        console.log(error);
                        updateLoadedSettingsCount();
                        setSettingsError(error);
                    }
                );
        }
    }, []); // runs once

    if (settingsError) {
        return <Typography color='error'>Error: {settingsError.message}</Typography>;
    }

    if (piecesToLoad > 0 || !userInfo) {
        return <Typography>Loading...</Typography>;
    }

    if (!Array.isArray(settings.admins)) {
        // when there's only one admin it's submitted as string instead of array, so wrap it.
        settings.admins = [settings.admins];
    }
    if (userInfo && settings.admins.includes(userInfo.preferred_username)) {
        return <AdminForm userInfo={userInfo} settings={settings} initialDebugSettings={debugSettings} />;
    }
    return <Typography color='error'>Weird: this shouldn&apos;t happen...</Typography>;
};

export default Admin;
