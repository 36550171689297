import React, { useEffect, useRef, useState } from 'react';

// Components
import { Box, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MomentsHeader } from '../../../Header/Header';

// Variables
import { landscapeTabletBreakpoint } from '../../../../Utils/constants/styles';

// Assets
import { ReactComponent as Camera } from '../../../../Assets/Icons/camera.svg';

// Styles
import { momentsSharedStyles } from '../../../../Utils/styles/global.style';
import { useWindowDimensions } from '../../../../Hooks/useWindowDimensions';

export const SnapLandingLeft = props => {
    const { scrollHeight, top, children } = props;
    const [visible, setVisible] = useState(false);
    const containerTop = top < 0 ? `${top}px` : '0px';
    const { t } = useTranslation();

    const windowSize = useWindowDimensions();

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current == null) {
            return;
        }

        let shouldBeVisible;
        if (windowSize.width < landscapeTabletBreakpoint) {
            shouldBeVisible = scrollHeight > containerRef.current.clientHeight;
        } else {
            shouldBeVisible = windowSize.width > landscapeTabletBreakpoint;
        }
        if (visible !== shouldBeVisible) {
            setVisible(shouldBeVisible);
        }
    }, [windowSize.width, scrollHeight, visible]);

    return (
        <Box sx={momentsSharedStyles.container(containerTop)} ref={containerRef}>
            <MomentsHeader />
            <Box sx={momentsSharedStyles.bottomContainer}>
                <SvgIcon
                    component={Camera}
                    viewBox='0 0 32 32'
                    sx={momentsSharedStyles.sectionImg}
                    alt={t('snap.title')}
                />
                <Box sx={momentsSharedStyles.headline}>{t('snap.title')}</Box>
                <Box sx={momentsSharedStyles.body}>{t('snap.description')}</Box>
                {children}
            </Box>
        </Box>
    );
};
