import { tokenStyles } from '../../../Utils/styles/global.style';

export const momentsNavigationStyle = {
    container: {
        mt: { xs: '32px', md: '50px' }
    },
    buttonHolder: isActive => ({
        ...tokenStyles.heading,
        display: 'inline-grid',
        gridTemplateColumns: 'max-content',
        gap: '8px',
        color: 'white.main',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        fontStyle: 'normal',
        textAlign: 'left',
        mr: '32px',
        cursor: 'pointer',
        pointerEvents: 'initial',
        zIndex: 500,
        opacity: isActive ? 1 : 0.4
    }),
    lineHolder: {
        height: 3,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    line: isActive => ({
        display: 'block',
        width: '100%',
        backgroundColor: 'white.main',
        height: isActive ? 3 : 1
    }),
    title: {
        ...tokenStyles.body,
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40],
        fontStyle: 'normal'
    }
};
