const OneTrustUtils = {
    callbacks: [],
    invokeCallbacks() {
        OneTrustUtils.callbacks.forEach(el => el());
    },
    registerCallback(callback) {
        if (typeof callback === 'function') {
            OneTrustUtils.callbacks.push(callback);
        }
    },
    unregisterCallback(callback) {
        if (typeof callback === 'function') {
            const idx = OneTrustUtils.callbacks.indexOf(callback);
            if (idx >= 0) {
                OneTrustUtils.callbacks.splice(idx, 1);
            }
        }
    },
    updateCookies() {
        // Assign OnetrustActiveGroups to custom variable
        function otGetInitialGrps() {
            OneTrustUtils.OptanonWrapperCount = '';
            OneTrustUtils.otIniGrps = window.OnetrustActiveGroups;
        }
        // Delete cookie
        function eraseCookie(name, hostName) {
            // Delete root path cookies
            const domainName = hostName || window.location.hostname;
            document.cookie = `${name}=; Max-Age=-99999999; Path=/;Domain=${domainName}`;
            document.cookie = `${name}=; Max-Age=-99999999; Path=/;Domain=.${domainName}`;
            document.cookie = `${name}=; Max-Age=-99999999; Path=/;`;
            // Delete LSO incase LSO being used, cna be commented out.
            localStorage.removeItem(name);
            // Check for the current path of the page
            const pathArray = window.location.pathname.split('/');
            // Loop through path hierarchy and delete potential cookies at each path.
            for (let i = 0; i < pathArray.length; i++) {
                if (pathArray[i]) {
                    // Build the path string from the Path Array e.g /site/login
                    const currentPath = pathArray.slice(0, i + 1).join('/');
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath};Domain=${domainName}`;
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath};Domain=.${domainName}`;
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath};`;
                    // Maybe path has a trailing slash!
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath}/;Domain=${domainName}`;
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath}/;Domain=.${domainName}`;
                    document.cookie = `${name}=; Max-Age=-99999999; Path=${currentPath}/;`;
                }
            }
        }
        // Get inactive ids
        function otGetInactiveId(customIniId, otActiveGrp) {
            // Initial OnetrustActiveGroups
            let customIniIds = customIniId.split(',');
            customIniIds = customIniIds.filter(Boolean);
            // After action OnetrustActiveGroups
            let otActiveGrps = otActiveGrp.split(',');
            otActiveGrps = otActiveGrps.filter(Boolean);
            const result = [];
            for (let i = 0; i < customIniIds.length; i++) {
                if (otActiveGrps.indexOf(customIniIds[i]) <= -1) {
                    result.push(customIniIds[i]);
                }
            }
            return result;
        }
        function otDeleteCookie(iniOptGrpId) {
            const otDomainGrps = JSON.parse(JSON.stringify(window.Optanon.GetDomainData().Groups));
            const otDeletedGrpIds = otGetInactiveId(iniOptGrpId, window.OnetrustActiveGroups);
            if (otDeletedGrpIds.length !== 0 && otDomainGrps.length !== 0) {
                for (let i = 0; i < otDomainGrps.length; i++) {
                    // Check if CustomGroupId matches
                    if (
                        otDomainGrps[i].CustomGroupId !== '' &&
                        otDeletedGrpIds.includes(otDomainGrps[i].CustomGroupId)
                    ) {
                        for (let j = 0; j < otDomainGrps[i].Cookies.length; j++) {
                            // Delete cookie
                            eraseCookie(otDomainGrps[i].Cookies[j].Name);
                            eraseCookie(otDomainGrps[i].Cookies[j].Name, otDomainGrps[i].Cookies[j].Host);
                        }
                    }
                    // Check if Hostid matches
                    if (otDomainGrps[i].Hosts.length !== 0) {
                        for (let j = 0; j < otDomainGrps[i].Hosts.length; j++) {
                            // Check if HostId presents in the deleted list and cookie array is not blank
                            if (
                                otDeletedGrpIds.includes(otDomainGrps[i].Hosts[j].HostId) &&
                                otDomainGrps[i].Hosts[j].Cookies.length !== 0
                            ) {
                                for (let k = 0; k < otDomainGrps[i].Hosts[j].Cookies.length; k++) {
                                    // Delete cookie
                                    eraseCookie(otDomainGrps[i].Hosts[j].Cookies[k].Name);
                                    eraseCookie(
                                        otDomainGrps[i].Hosts[j].Cookies[k].Name,
                                        otDomainGrps[i].Hosts[j].Cookies[k].Host
                                    );
                                }
                            }
                        }
                    }
                }
            }
            otGetInitialGrps(); // Reassign new group ids
            return otDeletedGrpIds.length !== 0;
        }
        // Get initial OnetrustActiveGroups ids
        if (typeof OneTrustUtils.OptanonWrapperCount === 'undefined') {
            otGetInitialGrps();
        }
        // Delete cookies
        return otDeleteCookie(OneTrustUtils.otIniGrps);
    }
};
window.OneTrustUtils = OneTrustUtils;
