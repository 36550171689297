import { headerLg } from '../../../../Utils/constants/styles';

export const snapLandingRightStyles = {
    content: {
        bgcolor: '#151515',
        overflowY: { xs: 'hidden', xl: 'scroll' },
        overflowX: 'hidden'
    },
    videoTiles: {
        minHeight: { xs: 'inherit', xl: `calc(100% - ${headerLg}px)` },
        width: { xs: '100%', xl: '50%' },
        left: { xs: 'inherit', xl: 'calc(50vw)' },
        position: 'relative',
        display: { xs: 'block', xl: 'grid' },
        justifyContent: 'center',
        alignContent: 'center',
        textAlign: 'center'
    },
    previewVideo: theme => ({
        position: 'inherit',
        '& video': {
            width: { xs: '100%', lg: '75%' },
            maxWidth: '1200px',
            margin: '0 auto',
            mt: { xs: 0, xl: '-100px' },
            [theme.breakpoints.down('xl')]: {
                height: 380
            }
        }
    }),
    snapButton: {
        mt: { xs: 0, xl: '8px' },
        mb: { xs: '40px', xl: 0 }
    },
    closeButton: {
        position: 'absolute',
        top: '3%',
        right: { xs: 15, md: '12%' },
        zIndex: 999
    },
    footer: theme => ({
        width: '100vw',
        bgcolor: 'black.main',
        opacity: '0.9',
        [theme.breakpoints.down('lg')]: {
            display: 'block'
        },
        [`@media (max-height: ${theme.breakpoints.values.lg})`]: {
            display: 'none'
        }
    }),
    icon: {
        height: 24,
        width: 24
    }
};
