import React, { memo, useEffect, useRef, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { isTablet, isMobile } from 'react-device-detect';
import { WebGlContainer } from '../WebGlContainer/WebGLContainer';
import { useDetectLogout } from '../../Hooks/useDetectLogout';
import {
    BRANCH_NAME,
    CLIENT_NAME,
    SERVER_ENDPOINT,
    FIREBASE_CONFIG,
    LOCALIZATION_DATA,
    USE_DEVELOPMENT,
    WEBGL_PUBLIC_URL,
    ANALYTICS_ENVIRONMENT
} from '../../environment';
import { ContentEncoding } from '../../contentEncoding';
import { Box } from '@mui/material';
import { webGLAppStyles } from './WebGLApp.style';

export const StorageKeys = {
    AppEndpoint: 'AppEndpoint',
    AppAccessToken: 'AppAccessToken',
    ClientName: 'ClientName',
    BranchName: 'BranchName',
    FirebaseConfig: 'FirebaseConfig',
    LocalizationSource: 'LocalizationSource',
    UserId: 'UserId',
    AppConfig: 'AppConfig',
    AnalyticsEnabled: 'AnalyticsEnabled',
    UseDevelopment: 'USE_DEVELOPMENT',
    AnalyticsEnvironment: 'AnalyticsEnvironment',
    oktaTokenStorage: 'okta-token-storage'
};

const WebGLApp = memo(({ projectName }) => {
    const { loggingOut } = useDetectLogout(`/`);
    const { authState } = useOktaAuth();

    const getAppConfig = () => {
        if (isTablet) {
            return 'Tablet';
        }
        if (isMobile) {
            return 'Mobile';
        }
        return 'Desktop';
    };

    useEffect(() => {
        localStorage.setItem(StorageKeys.AppAccessToken, authState.accessToken.accessToken);
        localStorage.setItem(StorageKeys.AppEndpoint, SERVER_ENDPOINT);
        localStorage.setItem(StorageKeys.ClientName, CLIENT_NAME);
        localStorage.setItem(StorageKeys.BranchName, BRANCH_NAME);
        localStorage.setItem(StorageKeys.FirebaseConfig, FIREBASE_CONFIG);
        localStorage.setItem(StorageKeys.LocalizationSource, LOCALIZATION_DATA);
        localStorage.setItem(StorageKeys.AppConfig, getAppConfig());
    }, [authState.accessToken.accessToken]);

    // default values, for production builds
    let contentEncoding = ContentEncoding.brotli;
    let includeSymbols = true;
    let webglPublicUrl = WEBGL_PUBLIC_URL;

    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /// / The code below contains debug only logic, i.e. part of it only runs when USE_DEVELOPMENT is enabled.

    const [settings, setSettings] = useState(null);

    const mutableAccessToken = useRef(authState.accessToken.accessToken);
    useEffect(() => {
        mutableAccessToken.current = authState.accessToken.accessToken;
    }, [authState.accessToken.accessToken]);

    useEffect(() => {
        if (USE_DEVELOPMENT) {
            // clear cache unless on the admin API page
            fetch(`${SERVER_ENDPOINT}/debugSettings`, {
                headers: {
                    authorization: `Bearer ${mutableAccessToken.current}`
                }
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result.error) {
                            console.log(result.error);
                        } else {
                            setSettings(result);
                        }
                    },

                    error => {
                        console.log(error);
                    }
                );
        }
    }, []); // runs once

    if (USE_DEVELOPMENT) {
        if (!settings) {
            return null;
        }

        if (settings.buildType === 'other' || settings.buildType === undefined) {
            contentEncoding =
                settings.webglBuildCompression !== undefined
                    ? ContentEncoding[settings.webglBuildCompression.toLowerCase()]
                    : contentEncoding;
            includeSymbols = settings.includeWebglSymbols !== undefined ? settings.includeWebglSymbols : includeSymbols;

            webglPublicUrl =
                settings.otherBuildPath && settings.otherBuildPath.trim()
                    ? `${WEBGL_PUBLIC_URL}/${settings.otherBuildPath.trim()}`
                    : webglPublicUrl;
        } else if (settings.buildType === 'cloud') {
            webglPublicUrl = `${WEBGL_PUBLIC_URL}/cloud/prod/${settings.buildNumber}`;
        } else {
            console.error(`Build type ${settings.buildType} not recognized!`);
            return null;
        }

        console.warn('DEVELOPMENT BUILD SETTINGS ENABLED!');
        localStorage.setItem(StorageKeys.UseDevelopment, 'true');
    }

    if (ANALYTICS_ENVIRONMENT != null) {
        localStorage.setItem(StorageKeys.AnalyticsEnvironment, ANALYTICS_ENVIRONMENT);
    } else {
        // default to using testing environment
        localStorage.setItem(StorageKeys.AnalyticsEnvironment, 'testing');
    }

    /// /////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (loggingOut) {
        return null;
    }

    return (
        <Box sx={webGLAppStyles.container}>
            <WebGlContainer
                projectName={projectName}
                contentEncoding={contentEncoding}
                includeSymbols={includeSymbols}
                webglPublicUrl={webglPublicUrl}
            />
        </Box>
    );
});

WebGLApp.displayName = 'WebGLApp';

export default WebGLApp;
