import { tokenStyles } from '../../../Utils/styles/global.style';

export const loginCallbackWithErrorStyles = {
    assignmentInd: { height: 72, width: 72 },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        background: theme => theme.palette.secondaryGradient.main,
        height: '100%',
        width: '100%'
    },
    errorMessage: {
        ...tokenStyles.body,
        fontSize: 'typeSize.60',
        lineHeight: theme => theme.typography.lineHeight[60],
        color: 'white.main',
        m: '32px 0',
        textAlign: 'center'
    }
};
