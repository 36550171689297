import { PlayCircleFilledWhite } from '@mui/icons-material';
import { Box, Button, createSvgIcon, IconButton, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DISPLAY_CLIENT_NAME, VIMEO_FALLBACK_LINK, VIMEO_FALLBACK_LINK_PASSWORD } from '../../environment';
import { vimeoFallbackStyles } from './VimeoFallback.style';

const ContentCopyIcon = createSvgIcon(
    <path d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z' />,
    'ContentCopy'
);

export const VimeoFallback = () => {
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { t } = useTranslation();

    const handleSnackbarClose = () => {
        setSnackbarMessage('');
    };

    return (
        <>
            <Box sx={vimeoFallbackStyles.container}>
                <Box component='span' sx={vimeoFallbackStyles.fallbackTitleText(true)}>
                    {t('loading.title', { clientName: DISPLAY_CLIENT_NAME })}
                </Box>
                <Box component='span' sx={vimeoFallbackStyles.fallbackTitleText(false)}>
                    {t('loading.text')}
                </Box>
                <div>
                    {VIMEO_FALLBACK_LINK_PASSWORD && (
                        <Box component='span' sx={vimeoFallbackStyles.passwordContainer}>
                            <Box component='span' sx={vimeoFallbackStyles.yourPasswordText}>
                                {t('loading.video_fallback_password_label')}
                            </Box>
                            <Box component='span' sx={vimeoFallbackStyles.passwordText}>
                                {VIMEO_FALLBACK_LINK_PASSWORD}
                            </Box>
                            <IconButton
                                onClick={() => {
                                    navigator.clipboard.writeText(VIMEO_FALLBACK_LINK_PASSWORD);
                                    setSnackbarMessage(t('loading.video_fallback_password_copied_label'));
                                }}>
                                <ContentCopyIcon fontSize='small' sx={vimeoFallbackStyles.copyContentIcon} />
                            </IconButton>
                        </Box>
                    )}
                    <Button
                        sx={vimeoFallbackStyles.viewOnVimeoButton}
                        variant='contained'
                        size='large'
                        endIcon={<PlayCircleFilledWhite />}
                        onClick={() => {
                            window.location = VIMEO_FALLBACK_LINK;
                        }}>
                        {t('loading.video_fallback_watch_label')}
                    </Button>
                </div>
            </Box>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={!!snackbarMessage}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </>
    );
};
