import { ANALYTICS_ENVIRONMENT, ANALYTICS_PROJECT_ID, CLIENT_NAME } from '../../environment';
import { v4 as uuidv4 } from 'uuid';
import { StorageKeys } from '../../Containers/App/WebGLApp';

export const UNITY_ANALYTICS_URL = `https://collect.analytics.unity3d.com/collect/api/project/${ANALYTICS_PROJECT_ID}/${ANALYTICS_ENVIRONMENT}`;
export const UNITY_ANALYTICS_UUID_URL = 'https://collect.analytics.unity3d.com/collect/api/uuid';

const getTimestamp = () => {
    // return a timestamp in the format 2016-03-01 20:26:50.148 and in UTC
    const d = new Date();
    return `${d.getUTCFullYear()}-${
        d.getUTCMonth() + 1
    }-${d.getUTCDate()} ${d.getUTCHours()}:${d.getUTCMinutes()}:${d.getUTCSeconds()}.${d.getUTCMilliseconds()}`;
};

// The some default value for the event
const generateData = () => ({
    userID: sessionStorage.getItem(StorageKeys.UserId),
    eventTimestamp: getTimestamp(),
    eventUUID: uuidv4()
});

const sendEvent = event => {
    const data = { eventList: [{ ...generateData(), ...defaultEvent, ...event }] };

    const datastring = JSON.stringify(data);

    fetch(UNITY_ANALYTICS_URL, {
        method: 'POST',
        body: datastring
    }).catch(error => {
        console.log('Error sending event', error);
    });
};

const defaultEvent = {
    eventParams: {
        serverClient: CLIENT_NAME
    }
};

export const recordSocialMediaDownloadAll = () => {
    const body = {
        eventName: 'socialMediaDownloadAll'
    };

    sendEvent(body);
};

export const recordIndividualMomentDownload = momentIdInput => {
    const body = {
        eventName: 'individualMomentDownload',
        eventParams: {
            serverClient: CLIENT_NAME,
            momentId: momentIdInput
        }
    };

    sendEvent(body);
};

export const recordAdminStateTransition = (oldState, newState) => {
    const body = {
        eventName: 'administratorStateTransition',
        eventParams: {
            oldValue: oldState,
            newValue: newState,
            serverClient: CLIENT_NAME
        }
    };
    sendEvent(body);
};

export const recordSnapShotTaken = () => {
    const body = {
        eventName: 'snapShotTaken'
    };

    sendEvent(body);
};

// Verify if performance cookie consent is active for user, and if allow analytics has been fetched from launch party api and is 'true'
export const getIsAnalyticsEnabled = () =>
    !!window.OnetrustActiveGroups?.includes('C0002') && sessionStorage.getItem(StorageKeys.AnalyticsEnabled) == 'true';
