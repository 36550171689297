import UnityLogo from '../../../Assets/Icons/UnityLogoWhite.svg';

export const adminHeaderStyles = {
    header: {
        boxSizing: 'border-box',
        width: '100%',
        height: 50,
        bgcolor: 'black.main',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        p: '0 40px'
    },
    itemText: { color: 'white.main', display: 'inline-block', mr: '15px', float: 'right', pt: '5px' },
    logo: {
        width: 36,
        height: 32,
        display: 'inline-block',
        backgroundImage: `url(${UnityLogo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'left center'
    },
    link: { marginRight: 'auto' }
};
