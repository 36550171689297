import { useOktaAuth } from '@okta/okta-react';
import { useState, useEffect, useCallback } from 'react';
import { StorageKeys } from '../../Containers/App/WebGLApp';
import { SERVER_ENDPOINT } from '../../environment';
import {
    getIsAnalyticsEnabled,
    recordAdminStateTransition,
    recordIndividualMomentDownload,
    recordSnapShotTaken,
    recordSocialMediaDownloadAll,
    UNITY_ANALYTICS_UUID_URL
} from './utils';

export const useUnityAnalytics = () => {
    const { authState } = useOktaAuth();
    const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(getIsAnalyticsEnabled());

    useEffect(() => {
        if (sessionStorage.getItem(StorageKeys.AnalyticsEnabled)) return;
        // if no value found, directly check firebase appsettings
        // this could be the case where the user went directly to celebration page without going through the WebGL instance first
        fetch(`${SERVER_ENDPOINT}/allowAnalytics`, {
            method: 'GET',
            headers: {
                authorization: `Bearer ${authState.accessToken.accessToken}`
            }
        })
            .then(response => response.json())
            .then(jsonResponse => {
                setIsAnalyticsEnabled(jsonResponse.analyticsEnabled === true);
                sessionStorage.setItem(StorageKeys.AnalyticsEnabled, jsonResponse.analyticsEnabled === true);
            });
    }, [authState]);

    useEffect(() => {
        if (isAnalyticsEnabled && !sessionStorage.getItem(StorageKeys.UserId)) {
            // this will not work with an ad-blocker, you will see a "Failed to load resource: net::ERR_BLOCKED_BY_CLIENT" message
            // Get userID if we don't already have one in localstorage
            fetch(UNITY_ANALYTICS_UUID_URL, {
                method: 'GET'
            }).then(uuidResponse =>
                uuidResponse.json().then(uuidData => {
                    sessionStorage.setItem(StorageKeys.UserId, uuidData.userID);
                })
            );
        }
    }, [isAnalyticsEnabled]);

    const recordSocialMediaDownloadAllGated = useCallback(
        props => isAnalyticsEnabled && recordSocialMediaDownloadAll(props),
        [isAnalyticsEnabled]
    );
    const recordIndividualMomentDownloadGated = useCallback(
        props => isAnalyticsEnabled && recordIndividualMomentDownload(props),
        [isAnalyticsEnabled]
    );
    const recordAdminStateTransitionGated = useCallback(
        props => isAnalyticsEnabled && recordAdminStateTransition(props),
        [isAnalyticsEnabled]
    );
    const recordSnapShotTakenGated = useCallback(props => isAnalyticsEnabled && recordSnapShotTaken(props), [
        isAnalyticsEnabled
    ]);

    return {
        recordSocialMediaDownloadAll: recordSocialMediaDownloadAllGated,
        recordIndividualMomentDownload: recordIndividualMomentDownloadGated,
        recordAdminStateTransition: recordAdminStateTransitionGated,
        recordSnapShotTaken: recordSnapShotTakenGated
    };
};
