import React, { useEffect, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';
import { ContentEncoding } from '../../contentEncoding';
import { NarrowFooter } from '../../Components/Header/Header';
import { ReactComponent as RotateLandscape } from '../../Assets/Icons/rotate_landscape.svg';
import { webGLContainerStyles } from './WebGLContainer.style';
import { Box } from '@mui/material';

const loaderId = 'unity-loader';
const canvasId = 'unity-canvas';
const containerId = 'unity-container';

export const WebGlContainer = ({ projectName, contentEncoding, webglPublicUrl, includeSymbols }) => {
    const [playerReady, setPlayerReady] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        // Prevent loading more than once.
        if (playerReady) return;

        const loaderElement = document.getElementById(loaderId);

        if (!loaderElement) {
            const loaderScript = document.createElement('script');
            const projectPrefix = `${webglPublicUrl}/Build/${projectName}`;

            loaderScript.src = `${projectPrefix}.loader.js`;
            loaderScript.id = loaderId;
            document.body.appendChild(loaderScript);

            const catProjectFile = fileType => projectPrefix + fileType + contentEncoding;

            const unityInstanceParams = {
                dataUrl: catProjectFile(`.data`),
                frameworkUrl: catProjectFile(`.framework.js`),
                codeUrl: catProjectFile(`.wasm`),
                streamingAssetsUrl: `${webglPublicUrl}/StreamingAssets`,
                companyName: `Unity Technologies`,
                productName: projectName,
                productVersion: `1.0.0`,
                backgroundColor: 'transparent'
                // matchWebGLToCanvasSize: false, // Uncomment this to separately control WebGL canvas render size and DOM element size.
                // devicePixelRatio: 1, // Uncomment this to override low DPI rendering on high DPI displays.
            };

            if (includeSymbols) {
                unityInstanceParams.symbolsUrl = catProjectFile(`.symbols.json`);
            }

            loaderScript.onload = () => {
                window
                    .createUnityInstance(document.getElementById(canvasId), unityInstanceParams)
                    .then(() => {
                        window.onUnityReady = () => {
                            setPlayerReady(true);
                        };
                    })
                    .catch(error => console.error(error));
            };

            // LP-3860 - disabling pinch-zoom on android, as it causes issues when orientation is changed.
            // issue was originally reported for iOS, but at this point it seems Apple has done a good job
            // preventing devs disabling the zoom feature.
            if (isMobile && !isIOS) {
                const viewportMeta = document.querySelector('meta[name="viewport"]');
                if (viewportMeta) {
                    viewportMeta.setAttribute(
                        'content',
                        'width=device-width, minimum-scale=1.0, maximum-scale=1.0, initial-scale=1.0, user-scalable=no'
                    );
                }
            }
        }
    });

    return (
        <>
            <Box sx={webGLContainerStyles.wrapper}>
                <Box id={containerId} sx={webGLContainerStyles.container}>
                    <Box component='canvas' id={canvasId} sx={webGLContainerStyles.canvas} />
                    <LoadingContainer playerReady={playerReady} />
                </Box>
                <Box sx={webGLContainerStyles.rotationPrompt}>
                    <Box sx={webGLContainerStyles.rotationImage}>
                        <RotateLandscape />
                    </Box>
                    <Box sx={webGLContainerStyles.rotationMessage}>{t('webgl.rotate_device_message')}</Box>
                </Box>
            </Box>
            <Box sx={webGLContainerStyles.footer}>
                <NarrowFooter />
            </Box>
        </>
    );
};

WebGlContainer.defaultProps = {
    contentEncoding: ContentEncoding.none
};
