import React, { useEffect, useMemo, useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Box, Button, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AssetGallery } from '../AssetGallery';
import { East } from '@mui/icons-material';
import LightSquare from '../../../../Assets/light-square.gif';
import LightRectangle from '../../../../Assets/light-rectangle.gif';
import { videoTileStyles } from './VideoTile.style';

function getScrollValue(index, number, scrollValue) {
    const min = (index - 1) / number;
    const max = index / number;

    let offset;
    if (index === 0) {
        offset = 0.0;
    } else if (index === number) {
        offset = 1.0;
    } else {
        offset = 0.5;
    }

    const clampedValue = Math.min(max, Math.max(min, scrollValue));
    return (clampedValue - min) / (max - min) - offset;
}

const LightGroup = props => {
    const { index } = props;
    const isEven = index % 2 === 0;
    return (
        <>
            {isEven && (
                <>
                    <Box
                        component='img'
                        sx={{ ...videoTileStyles.lightBox, top: 130, bottom: 1, right: -30 }}
                        src={LightSquare}
                        alt=''
                    />
                    <Box
                        component='img'
                        sx={{ ...videoTileStyles.lightBox, top: 250, left: 20, bottom: 1, right: 0 }}
                        src={LightSquare}
                        alt=''
                    />
                    <Box
                        component='img'
                        sx={{ ...videoTileStyles.lightBox, top: 420, bottom: 1, right: 0 }}
                        src={LightSquare}
                        alt=''
                    />
                </>
            )}
            {!isEven && (
                <>
                    <Box
                        component='img'
                        sx={{ ...videoTileStyles.lightBox, bottom: 40, right: -30 }}
                        src={LightSquare}
                        alt=''
                    />
                    <Box
                        component='img'
                        sx={{ ...videoTileStyles.lightBox, top: 150, left: 0, height: 250 }}
                        src={LightRectangle}
                        alt=''
                    />
                </>
            )}
        </>
    );
};

export const VideoTile = props => {
    const videoReference = useRef(null);
    const [visible, setVisible] = useState(false);
    const { index, videoSrc, posterSrc, numberOfTiles, descriptionLocId, scrollPercent, momentId } = props;
    const scrollValue = getScrollValue(index, numberOfTiles, scrollPercent);
    const { t } = useTranslation();

    const videoType = useMemo(() => (new URL(videoSrc).pathname.endsWith('.webm') ? 'video/webm' : 'video/mp4'), [
        videoSrc
    ]);

    useEffect(() => {
        const player = videoReference.current.children[0];
        if (!player) {
            return;
        }
        if (visible) {
            player.controls = false;
            player.playsinline = true;
            player.muted = true;
            // some browsers seem to fail to set the muted tag correctly... so lets be super sure
            player.setAttribute('muted', '');

            setTimeout(() => {
                const promise = player.play();
                if (promise.then) {
                    promise
                        .then(() => {})
                        .catch(error => {
                            console.log(error);
                            console.log('Unable to play video.');
                        });
                }
            });
        } else if (player.play) {
            player.pause();
        }
    }, [visible]);

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const videoOffset = -530;

    const lightGroupParallaxFactor = 0;
    const videoParallaxFactor = 0;
    const textParallaxFactor = 0;

    return (
        <Box sx={videoTileStyles.tile}>
            <Box sx={videoTileStyles.lightGroup(scrollValue * lightGroupParallaxFactor)}>
                <LightGroup index={index} />
            </Box>
            <Box sx={videoTileStyles.previewVideo(videoOffset - scrollValue * videoParallaxFactor)}>
                <VisibilitySensor delayedCall onChange={isVisible => setVisible(isVisible)}>
                    {/* Fix for video not autoplaying on mobile: */}
                    {/* https://medium.com/@BoltAssaults/autoplay-muted-html5-video-safari-ios-10-in-react-673ae50ba1f5 */}
                    <Box
                        ref={videoReference}
                        dangerouslySetInnerHTML={{
                            __html: `
                                <video
                                  loop
                                  muted
                                  playsInline
                                  preload='auto'
                                  poster=${posterSrc}
                                >
                                <source src=${videoSrc} type=${videoType} />
                                </video>`
                        }}
                    />
                </VisibilitySensor>
            </Box>
            <Box sx={{ marginTop: `${scrollValue * textParallaxFactor}px` }}>
                <Box sx={videoTileStyles.heading}>
                    <Box sx={videoTileStyles.number}>#{index}</Box>
                    <Box sx={videoTileStyles.headingText}>
                        {t(descriptionLocId)}
                        <br />
                        <Button sx={videoTileStyles.modalLink} onClick={handleOpen}>
                            {t('moments.view_moments_label')}&nbsp;
                            <East sx={videoTileStyles.arrowIcon} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Modal open={open}>
                <AssetGallery index={index} momentId={momentId} handleClose={handleClose} />
            </Modal>
        </Box>
    );
};

export default VideoTile;
