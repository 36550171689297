import { headerLg, headerSm } from '../Utils/constants/styles';
import { isMobileOnly } from 'react-device-detect';

export const rootStyles = {
    container: (showHeader, isLoading, height) => ({
        position: 'relative',
        display: isLoading ? 'none' : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'fontFamily',
        '@media screen and (orientation: portrait)': {
            height: {
                xs: showHeader ? `calc(${height}px - ${headerSm}px)` : `${height}px`,
                lg: showHeader ? `calc(${height}px - ${headerLg}px)` : `${height}px`
            }
        },
        '@media screen and (orientation: landscape)': {
            height: {
                xs: showHeader && !isMobileOnly ? `calc(${height}px - ${headerLg}px)` : `${height}px`,
                xl: showHeader && !isMobileOnly ? `calc(${height}px - ${headerLg}px)` : `${height}px`
            }
        }
    })
};
