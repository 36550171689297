import React from 'react';
import { useOktaAuth } from '@okta/okta-react';

const Logout = () => {
    const { oktaAuth } = useOktaAuth();
    const logout = async () => {
        oktaAuth.signOut('/');
    };
    return <>{logout()}</>;
};

export default Logout;
