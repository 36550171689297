import { tokenStyles } from '../../../../Utils/styles/global.style';

export const assetGalleryStyles = {
    assetPreviewContainer: isSelected => ({
        borderBottom: theme =>
            isSelected ? `4px solid ${theme.palette.primary.main}` : `4px solid ${theme.palette.black.main}`,
        width: 66
    }),
    assetPreviewImage: isSquare => ({
        width: isSquare ? 46 : 66,
        height: isSquare ? 62 : 44,
        pb: isSquare ? '0px 10px 0px 10px' : '10px'
    }),
    container: theme => ({
        bgcolor: 'black.main',
        color: 'white.main',
        height: '100%',
        [theme.breakpoints.down('xl')]: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    }),
    closeButton: {
        position: 'absolute',
        top: '3%',
        right: { xs: 15, md: '12%' }
    },
    selected: {
        height: '40vh',
        flex: { xs: 2, xl: 1 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 220,
        minWidth: 200,
        maxHeight: 400,
        maxWidth: 600,
        '& img': {
            maxWidth: '100%',
            maxHeight: { xs: '100%', xl: '60vh' },
            borderRadius: theme => theme.borderRadius.m
        },
        '& video': {
            maxWidth: '100%',
            maxHeight: { xs: '100%', xl: '60vh' },
            borderRadius: theme => theme.borderRadius.m
        }
    },
    buttonGroup: {
        display: { xs: 'inherit', xl: 'none' },
        mb: { xs: '24px', xl: 0 }
    },
    imageContainer: {
        display: 'grid',
        gridTemplateRows: 'auto',
        rowGap: '40px',
        textAlign: 'center'
    },
    previewHeading: {
        fontStyle: 'normal',
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: '#969696',
        textAlign: 'center',
        mb: '32px'
    },
    previewThumbnails: theme => ({
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xl')]: {
            display: 'none'
        }
    }),
    imageContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: 340,
        gap: '16px'
    },
    previewGallery: theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        gap: { xs: 0, xl: '96px' },
        px: '48px',
        [theme.breakpoints.down('xl')]: {
            flexDirection: 'column'
        }
    }),
    heading: {
        display: 'flex',
        gap: '24px'
    },
    headingText: {
        ...tokenStyles.heading,
        fontStyle: 'normal',
        fontSize: 'typeSize.60',
        lineHeight: theme => theme.typography.lineHeight[60],
        color: 'white.main'
    },
    number: theme => ({
        fontStyle: 'normal',
        fontWeight: 'fontWeight.countdown',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[60],
        color: 'white.main',
        [theme.breakpoints.down('xl')]: {
            width: 'inherit'
        }
    }),
    momentContent: {
        ...tokenStyles.body,
        ml: '40px',
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: '#BCBCBC'
    },
    momentSizes: {
        ml: '40px',
        width: 340,
        height: 50
    },
    buttonColor: {
        color: 'rgba(255, 255, 255, 0.15)',
        '&.Mui-selected': {
            color: 'primary.main'
        }
    },
    buttonSize: {
        width: 10,
        height: 10
    },
    downloadButtonContainer: {
        ml: '40px'
    },
    downloadEndIcon: { height: 24, width: 24 }
};
