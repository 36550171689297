import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

export const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions());
        };

        const debouncedResize = debounce(handleResize, 16, {
            leading: true,
            maxWait: 16,
            trailing: true
        });

        window.addEventListener('resize', debouncedResize, { passive: true });
        window.addEventListener('orientationchange', debouncedResize, { passive: true });
        return () => {
            window.removeEventListener('resize', debouncedResize);
            window.removeEventListener('orientationchange', debouncedResize);
        };
    }, []);

    return windowDimensions;
};
