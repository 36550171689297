export const reactionPagestyle = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',
        background: '#EFEFEF'
    },
    buttonContainer: { display: 'flex', justifyContent: 'center', mb: '24px' },
    recordButton: { backgroundColor: '#e00000', '&:hover': { backgroundColor: '#a80101' } },
    recordButtonLoadingIndicator: { color: '#e00000' },
    tableContainer: { width: 'auto', height: 'fit-content', mt: '40px', p: '40px', mb: '40px' },
    tableRow: { height: 72, '&:last-child td, &:last-child th': { border: 0 } },
    tableCell: { textAlign: 'center', width: 80, fontWeight: 900 },
    emptyStateText: { textAlign: 'center', pt: '40px' }
};
