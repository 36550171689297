import Background from '../../../Assets/RTTM_NYSE_BG.png';

export const landingPageStyle = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        backgroundImage: `url(${Background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        px: { xs: '30px', md: '90px', lg: '120px' }
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    topGradient: {
        position: 'absolute',
        width: '100%',
        height: { xs: '30%', lg: '40%' },
        top: 0,
        left: 0,
        background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
    },
    bottomGradient: {
        position: 'absolute',
        width: '100%',
        height: { xs: '50%', lg: '25%' },
        bottom: 0,
        left: 0,
        background: 'linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%)'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        p: '0px 8% min(15%, 100px) 8%',
        mt: { xs: '0px', md: '40px' },
        background: { xs: 'rgba(49, 45, 31, 0.62)', lg: 'rgba(27, 22, 7, 0.68)' },
        backdropFilter: 'blur(20px)',
        zIndex: 1
    },
    titleImage: (totalHeight, isExperienceAvailable) => ({
        maxHeight: Math.max(isExperienceAvailable ? totalHeight - 650 : totalHeight - 542, 300),
        maxWidth: '100%',
        mb: { xs: '16px', lg: '40px' },
        mt: { xs: '32px', lg: 0 },
        height: 'auto',
        alignSelf: 'center',
        objectFit: 'cover'
    }),
    cardContent: { display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center' },
    countdown: isHidden => ({
        display: isHidden ? 'none' : 'flex',
        fontWeight: 700,
        fontSize: { xs: '29px', lg: '60px' },
        letterSpacing: '0.3em',
        textTransform: 'uppercase',
        opacity: 0.8,
        color: '#FFFFFF'
    }),
    countdownUnit: { width: { xs: 57, lg: 115 }, display: 'flex', justifyContent: 'center' },
    icon: {
        fontSize: { xs: '18px !important', lg: '28px !important' }
    },
    enterExperienceButton: isVisible => ({
        display: isVisible ? 'flex' : 'none',
        borderRadius: 78,
        height: { xs: 38, lg: 60 },
        minWidth: { xs: 268, lg: 405 },
        mt: { xs: '32px', lg: '48px' },
        alignSelf: 'center',
        fontWeight: 700,
        fontSize: { xs: 14, lg: 22 },
        letterSpacing: '0.3em',
        textTransform: 'uppercase'
    }),
    bottomContent: {
        display: 'flex',
        flexDirection: { xs: 'column-reverse', xl: 'row' },
        alignItems: 'center',
        mt: { xs: '24px', lg: '30px' },
        gridGap: { xs: 24, lg: 48 },
        zIndex: 1
    },
    liveNowText: isVisible => ({
        display: isVisible ? 'flex' : 'none',
        color: '#FFFFFF',
        fontWeight: 700,
        fontSize: '24px',
        letterSpacing: '0.3em',
        textTransform: 'uppercase'
    }),
    liveNowDot: { color: '#FF0000', mr: '12px' },
    registrationContent: isVisible => ({
        display: isVisible ? 'flex' : 'none',
        flexDirection: { xs: 'column-reverse', xl: 'row' },
        alignItems: 'center',
        gridGap: { xs: 24, lg: 48 }
    }),
    registerButton: isRegistrationOpen => ({
        display: isRegistrationOpen ? 'flex' : 'none',
        borderRadius: 78,
        border: '2px solid #FFFFFF',
        height: { xs: 38, lg: 60 },
        minWidth: { xs: 268, lg: 353 },
        fontWeight: 700,
        fontSize: { xs: 13, lg: 22 },
        letterSpacing: '0.3em',
        textTransform: 'uppercase'
    }),
    registrationDate: {
        color: '#FFFFFF',
        textAlign: 'center',
        fontWeight: 700,
        fontSize: { xs: '11px', lg: '24px' },
        letterSpacing: '0.3em',
        lineHeight: '29px',
        textTransform: 'uppercase'
    },
    footer: {
        width: '100%',
        height: '88px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridGap: { xs: '24px', lg: '40px' },
        color: '#FFFFFF',
        opacity: 0.6,
        fontSize: '12px',
        lineHeight: '24px',
        fontWeight: 400
    }
};
