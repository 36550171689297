import { keyframes } from '@emotion/react';
import ModuleBG from '../../../../Assets/Dynamic/moduleBG.png';

const flashAnimation = keyframes`
  '0%': {
    opacity: 0
  },
  '50%': {
    opacity: 1
  },
  '100%': {
    opacity: 0
  }
`;

const confettiAnimation = keyframes`
  '0%': {
    opacity: 1
  },
  '75%': {
    opacity: 0.75
  },
  '100%': {
    opacity: 1
  }
`;

export const snapModalStyles = {
    container: theme => ({
        position: 'absolute',
        width: '100vw',
        bgcolor: 'black.main',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        color: 'white.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        backgroundImage: `url(${ModuleBG})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('xl')]: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    }),
    mainHolder: theme => ({
        m: '5px auto',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        p: { xs: '16px', xl: '16px 16px 32px 16px' },
        backgroundColor: 'rgba(0,0,0,0.24)',
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: theme => theme.borderRadius.lg,
        mt: '-80px',
        '&>*': {
            gridRow: '1',
            gridColumn: '1'
        },
        [theme.breakpoints.down('lg')]: {
            minWidth: 'inherit'
        }
    }),
    mainHolderSmall: theme => ({
        m: '5px auto',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        width: 1200,
        minWidth: 720,
        p: { xs: '16px', xl: '16px 16px 32px 16px' },
        backgroundColor: 'rgba(0,0,0,0.24)',
        border: '1px solid rgba(255, 255, 255, 0.24)',
        borderRadius: theme => theme.borderRadius.lg,
        marginTop: '-80px',
        '&>*': {
            gridRow: '1',
            gridColumn: '1'
        },
        [theme.breakpoints.down('lg')]: {
            width: 350,
            height: 350,
            minWidth: 'inherit'
        }
    }),
    invisible: {
        opacity: 0,
        position: 'absolute',
        left: '-200%',
        top: '-200%',
        pointerEvents: 'none'
    },
    snapCanvas: theme => ({
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        gridTemplateRows: 'min-content auto auto auto',
        mb: { xs: 0, lg: '75px' },
        position: 'relative',
        backgroundPosition: 'top center',
        backgroundSize: '101% auto',
        backgroundRepeat: 'no-repeat',
        height: { xs: 350, lg: 360, xl: 500 },
        width: { xs: 350, lg: 720, xl: 1000 },
        [theme.breakpoints.down('lg')]: {
            backgroundImage: 'none',
            '&>*': {
                gridColumn: '1',
                gridRow: '1'
            }
        }
    }),
    videoHolder: {
        gridColumn: '1',
        gridRow: '1',
        justifySelf: 'center',
        alignSelf: 'start',
        overflow: 'hidden',
        backgroundColor: 'rgba(0,0,0,1)',
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        fontSize: 'typeSize.100',
        fontStyle: 'normal',
        '&>*': {
            gridColumn: '1',
            gridRow: '1'
        },
        '&>video': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            margin: '0 auto'
        },
        mt: { xs: 0, lg: '15px', xl: '25px' },
        height: { xs: 350, lg: 245, xl: 340 },
        width: { xs: 350, lg: 245, xl: 340 }
    },
    count: {
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        zIndex: 99,
        backgroundColor: 'rgba(0,0,0,0.5)'
    },
    flash: {
        height: '100%',
        width: '100%',
        zIndex: 99,
        boxShadow: 'inset 0 0 1000px white',
        animation: `${flashAnimation} 500ms normal forwards ease-in-out`
    },
    confettiHolder: theme => ({
        '&>img': {
            position: 'absolute',
            width: '100%',
            maxWidth: 590,
            zIndex: 999,
            animation: `${confettiAnimation} 1800ms normal forwards ease-in-out`
        },
        '&>img:first-of-type': {
            left: -90,
            top: 0
        },
        '&>img:last-of-type': {
            right: -90,
            top: 20
        },
        [theme.breakpoints.down('lg')]: {
            '&>img': {
                maxWidth: 350
            },
            '&>img:first-of-type': {
                top: '-35%',
                left: '-40%'
            },
            '&>img:last-of-type': {
                top: '25%',
                right: '-35%'
            }
        }
    }),
    renderedImg: {
        width: '100%',
        maxWidth: 722
    },
    snapButton: {
        m: '28px'
    },
    snapButtonInline: {
        m: '16px'
    },
    buttonHolder: {
        height: 53
    },
    canvas: {
        position: 'absolute',
        top: '-150%',
        left: '-150%',
        pointerEvents: 'none',
        opacity: '0'
    },
    displayFrame: theme => ({
        width: '100%',
        gridColumn: '1',
        gridRow: '1',
        zIndex: 990,
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    }),
    icons: {
        height: 24,
        width: 24,
        color: 'white.main'
    }
};
