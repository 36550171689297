// custom Hook
import { useState, useCallback } from 'react';

const useInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const onChange = useCallback(({ target }) => {
        const newValue = target.type === 'checkbox' ? target.checked : target.value;
        setValue(newValue);
    }, []);
    return {
        value,
        setValue,
        onChange
    };
};
export default useInput;
