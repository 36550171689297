const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const reduceTokenEntries = ({ tokens, isColor, isPxValue, suffix, arr = [] }) =>
    Object.entries(tokens).reduce((bColors, [k, v]) => {
        if (v?.value) {
            const parsedKey = k + suffix; // Set parent key as child key's suffix
            const parsedValue = isColor ? { main: v.value } : `${v.value}${isPxValue ? 'px' : ''}`; // Add nested "main" key if color, else add px suffix
            return bColors.concat([[parsedKey, parsedValue]]);
        } else {
            const capitalizedKeySuffix = capitalize(suffix) + capitalize(k); // Capitalize last suffix and key to format as camelCase
            return reduceTokenEntries({ tokens: v, isColor, isPxValue, suffix: capitalizedKeySuffix, arr: bColors });
        }
    }, arr);

export const mapNestedTokens = ({ tokens, isColor = false, isPxValue = false, suffix = '' }) =>
    Object.fromEntries(reduceTokenEntries({ tokens, isColor, isPxValue, suffix }));

const parseDropShadow = token => {
    const shadow = Object.values(token);
    const [x, y, blur, spread, color] = shadow.map(s => s.toString());
    return `${x}px ${y}px ${blur}px ${spread}px ${color}`;
};

export const mapShadows = tokens =>
    Object.fromEntries(Object.entries(tokens).map(([k, v]) => [k, parseDropShadow(v.value)]));
