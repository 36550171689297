import { OKTA_ISSUER, OKTA_CLIENT_ID, OKTA_IDP, OKTA_MULTIPLE_IDP } from './environment';

export const CALLBACK_PATH = '/implicit/callback';
const REDIRECT_URI = window.location.origin + CALLBACK_PATH;

export const oktaConfig = {
    clientId: OKTA_CLIENT_ID,
    issuer: OKTA_ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    idp: OKTA_IDP,
    multipleIDP: OKTA_MULTIPLE_IDP ? JSON.parse(OKTA_MULTIPLE_IDP) : undefined
};
