import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import { East } from '@mui/icons-material';
import { LandingPageHeader } from '../LandingPageHeader';
import TEXT from '../../../Assets/RTTM_TEXT.png';
import { useCountDown } from '../../../Hooks/useCountDown';
import {
    EVENT_START_TIME,
    IS_EXPERIENCE_AVAILABLE,
    REGISTRATION_CUT_OFF_MINS,
    REGISTRATION_LINK
} from '../../../environment';
import { landingPageStyle } from './LandingPage.style';
import { useWindowDimensions } from '../../../Hooks/useWindowDimensions';

const eventStartDateTime = new Date(EVENT_START_TIME);
const eventTimeEST = eventStartDateTime
    .toLocaleString('en-US', { timeZone: 'america/new_york', hour: 'numeric' })
    .replace(/ /g, '');
const eventTimePST = eventStartDateTime
    .toLocaleString('en-US', { timeZone: 'America/Vancouver', hour: 'numeric' })
    .replace(/ /g, '');
const dateParts = eventStartDateTime.toDateString().split(' ');
const eventStartString = `${eventTimeEST} EST • ${eventTimePST} PST • ${dateParts[1]} ${dateParts[2]}, ${dateParts[3]}`;
const getDisplayTime = timeUnit => (timeUnit < 10 ? `0${timeUnit}` : timeUnit);

export const LandingPage = () => {
    const { height } = useWindowDimensions();
    const history = useHistory();
    const [days, hours, minutes, seconds] = useCountDown(EVENT_START_TIME);
    const eventHasStarted = days + hours + minutes + seconds <= 0;
    const isRegistrationOpen = days * 24 * 60 + hours * 60 + minutes >= REGISTRATION_CUT_OFF_MINS;

    const redirectToLogin = () => history.push('/login');

    return (
        <Box sx={landingPageStyle.container}>
            <LandingPageHeader />
            <Box sx={landingPageStyle.content}>
                <Box sx={landingPageStyle.topGradient} />
                <Box sx={landingPageStyle.card}>
                    <Box component='img' src={TEXT} sx={landingPageStyle.titleImage(height, IS_EXPERIENCE_AVAILABLE)} />
                    <Box sx={landingPageStyle.cardContent}>
                        <Box sx={landingPageStyle.countdown(eventHasStarted)}>
                            <Box sx={landingPageStyle.countdownUnit}>{getDisplayTime(days)}</Box>:
                            <Box sx={landingPageStyle.countdownUnit}>{getDisplayTime(hours)}</Box>:
                            <Box sx={landingPageStyle.countdownUnit}>{getDisplayTime(minutes)}</Box>:
                            <Box sx={{ ...landingPageStyle.countdownUnit, justifyContent: 'flex-start' }}>
                                {getDisplayTime(seconds)}
                            </Box>
                        </Box>
                        <Button
                            variant='contained'
                            color='primary'
                            endIcon={<East sx={landingPageStyle.icon} />}
                            onClick={redirectToLogin}
                            sx={landingPageStyle.enterExperienceButton(IS_EXPERIENCE_AVAILABLE)}>
                            Enter Experience
                        </Button>
                    </Box>
                </Box>
                <Box sx={landingPageStyle.bottomContent}>
                    <Box sx={landingPageStyle.liveNowText(eventHasStarted)}>
                        <Box component='span' sx={landingPageStyle.liveNowDot}>
                            •
                        </Box>
                        Live Now
                    </Box>
                    <Box sx={landingPageStyle.registrationContent(!eventHasStarted)}>
                        <Button
                            href={REGISTRATION_LINK}
                            target='_blank'
                            variant='outlined'
                            color='white'
                            endIcon={<East sx={landingPageStyle.icon} />}
                            sx={landingPageStyle.registerButton(isRegistrationOpen)}>
                            Register
                        </Button>
                        <Box sx={landingPageStyle.registrationDate}>{eventStartString}</Box>
                    </Box>
                </Box>
                <Box sx={landingPageStyle.bottomGradient} />
            </Box>
            <Box sx={landingPageStyle.footer}>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal'
                    sx={{ color: '#FFFFFF' }}>
                    Legal
                </Box>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal/privacy-policy'
                    sx={{ color: '#FFFFFF' }}>
                    Privacy Policy
                </Box>
                <Box sx={{}}>© 2023 Unity Technologies</Box>
            </Box>
        </Box>
    );
};
