import React, { useState, useEffect } from 'react';

// Dependencies
import { Box, IconButton, Modal, SvgIcon } from '@mui/material';
import QRCode from 'qrcode';

// SVG elements
import { ReactComponent as Close } from '../../../Assets/Icons/close.svg';
import { ReactComponent as MenuBars } from '../../../Assets/Icons/menu.svg';
import { ReactComponent as Camera } from '../../../Assets/Icons/camera.svg';
import { ReactComponent as Moments } from '../../../Assets/Icons/moments.svg';

//Images
import { useTranslation } from 'react-i18next';
import { menuModalStyles } from './MenuModal.style';
import { tokenStyles } from '../../../Utils/styles/global.style';

export const MenuModal = props => {
    const [qrCodeSource, setQrCodeSource] = useState(null);
    const [open, setOpen] = useState(false);
    const { currentSection, goToSnap, goToMoments } = props;

    const { t } = useTranslation();

    useEffect(() => {
        QRCode.toDataURL(window.location.href).then(data => {
            setQrCodeSource(data);
        });
    }, []);

    const goTo = S => {
        if (S === 'share') goToMoments();
        if (S === 'snap') goToSnap();
        setOpen(false);
    };

    return (
        <Box component='menu' sx={menuModalStyles.Menu}>
            <>
                {!open && (
                    <Box sx={menuModalStyles.MenuIcon} onClick={() => setOpen(true)}>
                        <MenuBars sx={menuModalStyles.menuBars} />
                    </Box>
                )}
                <Modal open={open} sx={menuModalStyles.MenuModalComponent}>
                    <Box sx={menuModalStyles.MenuModalClass}>
                        <IconButton sx={menuModalStyles.closeButton} onClick={() => setOpen(false)}>
                            <Close />
                        </IconButton>
                        <Box sx={menuModalStyles.MenuContentHolder}>
                            <Box sx={menuModalStyles.titleSection}>
                                <Box sx={tokenStyles.heading}>{t('celebration.menu_modal.title')}</Box>
                                <Box sx={menuModalStyles.modalDescription}>{t('celebration.menu_modal.text')}</Box>
                            </Box>
                            <Box sx={menuModalStyles.MenuButtonHolder}>
                                <Box
                                    sx={menuModalStyles.MenuButton(currentSection === 'Snap')}
                                    onClick={() => goTo('snap')}>
                                    <SvgIcon
                                        component={Camera}
                                        viewBox='0 0 32 32'
                                        alt={t('snap.title')}
                                        sx={menuModalStyles.svgIcon}
                                    />
                                    <Box sx={tokenStyles.heading}>{t('snap.title')}</Box>
                                    <Box sx={menuModalStyles.menuButtonDescription}>
                                        {t('celebration.menu_modal.snap_description')}
                                    </Box>
                                </Box>
                                <Box
                                    sx={menuModalStyles.MenuButton(currentSection === 'Share')}
                                    onClick={() => goTo('share')}>
                                    <SvgIcon
                                        component={Moments}
                                        viewBox='0 0 128 128'
                                        alt={t('moments.title')}
                                        sx={menuModalStyles.svgIcon}
                                    />
                                    <Box sx={tokenStyles.heading}>{t('moments.title')}</Box>
                                    <Box sx={menuModalStyles.menuButtonDescription}>
                                        {t('celebration.menu_modal.moments_description')}
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={menuModalStyles.MenuOr}>
                                <Box>
                                    <Box sx={menuModalStyles.line} />
                                </Box>
                                <Box>{t('celebration.menu_modal.or_word')}</Box>
                                <Box>
                                    <Box sx={menuModalStyles.line} />
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={menuModalStyles.MenuQR}>
                                    <Box
                                        component='img'
                                        src={qrCodeSource}
                                        sx={menuModalStyles.qrCode}
                                        alt={t('celebration.menu_modal.qr_code_alt')}
                                    />
                                    <Box>
                                        <Box sx={menuModalStyles.qrTitle}>
                                            {t('celebration.menu_modal.mobile_website_title')}
                                        </Box>
                                        <Box sx={menuModalStyles.qrDescription}>
                                            {t('celebration.menu_modal.mobile_website_message')}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </>
        </Box>
    );
};
