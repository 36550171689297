import React from 'react';
import { Box } from '@mui/material';
import { adminHeaderStyles } from './AdminHeader.style';

const AdminHeader = ({ children }) => (
    <Box sx={adminHeaderStyles.header}>
        <Box component='a' href='/' sx={adminHeaderStyles.link}>
            <Box sx={adminHeaderStyles.logo} />
            <Box sx={adminHeaderStyles.itemText}>Back to app</Box>
        </Box>
        {children}
    </Box>
);

export default AdminHeader;
