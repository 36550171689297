import { keyframes } from '@emotion/react';

// Measured in seconds
const rippleAnimation = keyframes`
  0% { top: 46%; left: 46%; width: 0; height: 0; opacity: 1; }
  100% { top: 0; left: 0; width: 92%; height: 92%; opacity: 0; }
`;

export const LOADING_TRANSITION = 2;

export const loadingContainerStyles = {
    logoRelativeContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    loadingScrim: isLoading => ({
        position: 'absolute',
        top: '0',
        left: '0',
        aspectRatio: '16 / 9',
        width: '100%',
        height: 'auto',
        zIndex: 99,
        bgcolor: 'black.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        opacity: isLoading ? 1 : 0,
        transition: `opacity ${LOADING_TRANSITION}s`
    }),
    ripplesContainer: {
        height: 56,
        width: 56,
        overflow: 'hidden',
        bgcolor: 'black.main',
        display: 'flex',
        justifyContent: 'space-between',
        mb: '24px'
    },
    rippleLoaderChild: {
        position: 'absolute',
        border: '2px solid #fff',
        opacity: 1,
        borderRadius: '50%',
        animation: `${rippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite`
    },
    rippleLoaderParent: {
        display: 'inline-block',
        position: 'relative',
        width: '100%',
        height: '100%'
    }
};
