import tokensJSON from './defaultTokens.json';

export const OKTA_ISSUER = process.env.REACT_APP_OKTA_ISSUER;
export const OKTA_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
export const OKTA_IDP = process.env.REACT_APP_OKTA_IDP;
export const OKTA_MULTIPLE_IDP = process.env.REACT_APP_OKTA_MULTIPLE_IDP;
export const SERVER_ENDPOINT = process.env.REACT_APP_ENDPOINT;
export const CLIENT_NAME = process.env.REACT_APP_CLIENT_NAME;
export const DISPLAY_CLIENT_NAME = process.env.REACT_APP_DISPLAY_CLIENT_NAME || process.env.REACT_APP_CLIENT_NAME;
export const BRANCH_NAME = process.env.REACT_APP_BRANCH_NAME;
export const VIMEO_FALLBACK_LINK = process.env.REACT_APP_VIMEO_FALLBACK_LINK;
export const VIMEO_FALLBACK_LINK_PASSWORD = process.env.REACT_APP_VIMEO_FALLBACK_LINK_PASSWORD;
export const FIREBASE_CONFIG = process.env.REACT_APP_FIREBASE_CONFIG;
export const WEBGL_PUBLIC_URL = process.env.REACT_APP_WEBGL_PUBLIC_URL;
export const LOCALIZATION_DATA = process.env.REACT_APP_LOCALIZATION_DATA;
export const PHOTOBOOTH_HIDDEN = (process.env.REACT_APP_HIDE_PHOTOBOOTH || 'false').toLowerCase() === 'true';
export const MOMENTS_HIDDEN = (process.env.REACT_APP_HIDE_MOMENTS || 'false').toLowerCase() === 'true';
export const CELEBRATION_ENABLED = !MOMENTS_HIDDEN || !PHOTOBOOTH_HIDDEN;
export const USE_DEVELOPMENT = (process.env.REACT_APP_USE_DEVELOPMENT || 'false').toLowerCase() === 'true';
export const ANALYTICS_PROJECT_ID = process.env.REACT_APP_ANALYTICS_PROJECT_ID;
export const ANALYTICS_ENVIRONMENT = process.env.REACT_APP_ANALYTICS_ENVIRONMENT;
const envTokens = () => {
    try {
        return { ...tokensJSON, ...JSON.parse(process.env.REACT_APP_TOKENS) };
    } catch {
        return tokensJSON;
    }
};
export const DESIGN_TOKENS = envTokens();
export const EVENT_START_TIME = process.env.REACT_APP_EVENT_START_TIME;
export const IS_EXPERIENCE_AVAILABLE = process.env.REACT_APP_IS_EXPERIENCE_AVAILABLE === 'true';
export const REGISTRATION_CUT_OFF_MINS = process.env.REACT_APP_REGISTRATION_CUT_OFF_MINS;
export const REGISTRATION_LINK = process.env.REACT_APP_REGISTRATION_LINK;
