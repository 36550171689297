import { tokenStyles } from '../../../Utils/styles/global.style';

export const loginStyles = {
    container: {
        display: { xs: 'flex', md: 'unset' },
        flexDirection: { xs: 'column', md: 'unset' },
        width: '100%',
        height: '100%'
    },
    page: {
        background: theme => theme.palette.secondaryGradient.main,
        height: 'inherit',
        overflowY: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
    },
    card: {
        bgcolor: 'white.main',
        borderRadius: theme => theme.borderRadius.m,
        p: { xs: '24px 20px', md: '32px 32px' },
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    messageCard: {
        maxWidth: 833,
        bgcolor: 'black.main',
        color: 'white.main',
        p: '16px 24px',
        gap: '4px',
        alignItems: 'left',
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: { xs: 0, md: '40px' },
        left: { xs: 0, md: '50%' },
        transform: { md: 'translateX(-50%)' },
        borderRadius: theme => ({ xs: 0, md: theme.borderRadius.m }),
        width: { md: '80%' }
    },
    messageTitle: {
        ...tokenStyles.heading,
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        mb: '8px'
    },
    messageText: {
        ...tokenStyles.body,
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40]
    },
    loginButtonWrapper: {
        padding: '0px 20px 88px 20px'
    },
    loginButton: {
        borderRadius: theme => theme.borderRadius.sm,
        width: '100%',
        fontSize: 'typeSize.60',
        lineHeight: theme => theme.typography.lineHeight[60],
        justifyContent: 'space-between',
        p: { xs: '16px 24px', md: '20px 24px' },
        alignItems: 'center',
        mb: 1
    },
    loginGuestButton: {
        textDecoration: 'underline',
        letterSpacing: 0,
        color: 'dark.main',
        mt: '24px'
    },
    footer: {
        backgroundColor: 'black.main',
        display: { xs: 'flex', md: 'none' },
        height: { xs: 'auto', md: 0 },
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative'
    },
    endIcon: {
        height: 32,
        width: 32
    }
};
