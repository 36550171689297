import { headerLg, headerSm } from '../../Utils/constants/styles';

export const headerStyles = {
    clientLogo: theme => ({
        m: 0,
        p: 0,
        border: 0,
        height: 32,
        width: 'auto',
        // LP-4654: reduced logo size on mobile.
        '@media (orientation: portrait)': {
            [theme.breakpoints.down('md')]: {
                maxHeight: 24
            }
        }
    }),
    copyright: {
        color: 'white.main',
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[60]
    },
    copyrightNarrow: {
        color: 'white.main',
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[60],
        mt: '8px',
        textAlign: 'center'
    },
    header: {
        width: '100%',
        p: '0px 18px 0px 5%',
        height: headerLg,
        bgcolor: 'black.main',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: 'white.main',
        boxSizing: 'border-box',
        maxWidth: 'calc(100% * 16 / 9)',
        m: '0 auto',
        webkitUserSelect: 'none' /* Safari */,
        KhtmlUserSelect: 'none' /* Konqueror HTML */,
        MozUserSelect: 'none' /* Firefox */,
        MsUserSelect: 'none' /* Internet Explorer/Edge */,
        userSelect: 'none' /* supported by Chrome and Opera */
    },
    headerRight: {
        flexShrink: 0,
        opacity: 0.5
    },
    headerMiddle: {
        position: 'sticky',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        minWidth: 420,
        height: 32,
        mt: { xs: '32px', xl: '4.5vh' },
        ml: { xs: '20px', xl: '20%' }
    },
    headerCenter: {
        width: '100%',
        p: '20px 5%',
        bgcolor: 'black.main',
        alignItems: 'center',
        color: 'white.main',
        boxSizing: 'border-box',
        justifyContent: 'center',
        verticalAlign: 'baseline',
        display: 'grid',
        gridAutoFlow: 'row'
    },
    headerLeft: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '32px',
        opacity: 1
    },
    headerLogo: { height: 44, width: 'auto' },
    headerLogoSm: { height: 35, width: 'auto' },
    legal: {
        display: 'flex',
        justifyContent: 'space-between',
        pl: '20px'
    },
    legalCenter: {
        display: 'grid',
        gridAutoFlow: 'column',
        gridColumnGap: '10px',
        justifyContent: 'center',
        mt: '16px'
    },
    link: {
        color: 'white.main',
        fontSize: 'typeSize.40',
        textDecoration: 'underline'
    },
    listingDay: {
        fontStyle: 'normal',
        fontWeight: 'fontWeight.body',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: 'white.main'
    },
    mobileHeader: theme => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: headerSm,
        '@media (orientation: landscape)': {
            [theme.breakpoints.down('xl')]: {
                display: 'none'
            }
        }
    }),
    momentsVerticalBar: {
        height: 20,
        width: '1px',
        bgcolor: 'white.main',
        margin: '0px 20px'
    },
    NYSEVerticalBar: {
        height: 32,
        width: '1px',
        bgcolor: 'white.main'
    },
    svgIcon: { height: 32, width: 88 },
    cookieConsentParent: { marginLeft: '36px', cursor: 'pointer' },
    headerRightRoot: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 1
    },
    mobileCookieConsent: {
        top: { xs: 12, md: 15 },
        right: { xs: 12, md: 15 },
        opacity: 1,
        position: 'fixed',
        zIndex: 1
    },
    cookieConsentIcon: {
        height: 48,
        width: 48
    },
    cookieConsentIconSmall: {
        height: 36,
        width: 36
    }
};
