import React, { useEffect, useRef, useState } from 'react';
import { Box, useMediaQuery, useTheme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FiberManualRecord } from '@mui/icons-material';
import debounce from 'lodash.debounce';
import { useOktaAuth } from '@okta/okta-react';
import { engineName } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { VideoTile } from '../VideoTile';
import { ReactComponent as VerticalStroke } from '../../../../Assets/Icons/vertical_stroke.svg';
import { NarrowFooter, Footer } from '../../../Header/Header';
import { SERVER_ENDPOINT } from '../../../../environment';
import { momentLandingRightStyles } from './MomentLandingRight.style';

export const MomentLandingRight = props => {
    const [data, updateData] = useState([]);
    const [scrollPercent, setScrollPercent] = useState(0);
    const { setMomentLandingLeftTop } = props;
    const { authState } = useOktaAuth();
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));

    const isWebKit = engineName.toLowerCase() === 'webkit';

    useEffect(() => {
        const getData = () => {
            fetch(`${SERVER_ENDPOINT}/moments`, {
                method: 'GET',
                headers: {
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            })
                .then(response => response.json())
                .then(responseData => updateData(responseData.moments))
                .catch(error => console.log(error));
        };
        getData();
    }, [authState.accessToken.accessToken]);

    const [position, setPosition] = React.useState(0);
    const scrollableContent = useRef(null);

    const handlePosition = (event, newPosition) => {
        if (newPosition !== null && data) {
            const scrollPosition = (newPosition * scrollableContent.current.scrollHeight) / data.length;
            scrollableContent.current.scroll({ top: scrollPosition, left: 0, behavior: 'smooth' });
        }
    };

    const videoTilesRef = useRef(null);
    const handleScroll = e => {
        if (!e.target || !data) {
            return;
        }
        const winScroll = e.target.scrollTop;
        const height = e.target.scrollHeight - e.target.clientHeight;

        if (videoTilesRef) {
            setMomentLandingLeftTop(videoTilesRef.current.getBoundingClientRect().bottom - e.target.clientHeight);
        }

        const scrolled = winScroll / height;
        setScrollPercent(scrolled);
        let newScrollPos = Math.floor(scrolled * data.length);
        newScrollPos = newScrollPos > data.length - 1 ? data.length - 1 : newScrollPos;
        setPosition(newScrollPos);
    };

    const footer = matches ? <Footer /> : <NarrowFooter />;

    if (!data) {
        return null;
    }

    return (
        <Box
            sx={momentLandingRightStyles.content}
            onScroll={debounce(handleScroll, 100, {
                leading: true,
                maxWait: 100,
                trailing: true
            })}
            ref={scrollableContent}>
            <Box sx={momentLandingRightStyles.sidebar}>
                <Box sx={momentLandingRightStyles.sidebarTextGroup}>
                    <Box sx={momentLandingRightStyles.verticalStroke}>
                        <VerticalStroke />
                    </Box>
                    <Box sx={momentLandingRightStyles.sidebarText}>{t('moments.scroll_down_label')}</Box>
                </Box>
                <Box sx={momentLandingRightStyles.buttonGroup}>
                    <ToggleButtonGroup orientation='vertical' value={position} exclusive onChange={handlePosition}>
                        {data.map((item, index) => (
                            <ToggleButton key={item.momentID} value={index} sx={momentLandingRightStyles.buttonColor}>
                                <FiberManualRecord sx={momentLandingRightStyles.buttonSize} />
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
            </Box>
            <Box sx={momentLandingRightStyles.videoTiles} ref={videoTilesRef}>
                {data.map((item, index) => (
                    <VideoTile
                        key={item.momentID}
                        index={index + 1}
                        momentId={item.momentID}
                        descriptionLocId={item.description}
                        videoSrc={isWebKit ? item.previewVideoUrlSafari : item.previewVideoUrl}
                        posterSrc={item.previewPosterUrl}
                        scrollPercent={scrollPercent}
                        numberOfTiles={data.length}
                    />
                ))}
            </Box>
            <Box sx={momentLandingRightStyles.footer}>{footer}</Box>
        </Box>
    );
};
