export const adminWrapperStyles = {
    wrapper: {
        bgcolor: '#18434a',
        boxSizing: 'border-box',
        p: '40px',
        color: '#f3f3f3',
        width: '100%',
        height: '100%',
        overflow: 'auto'
    }
};
