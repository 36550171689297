export const adminFormStyles = {
    form: { my: '10px', mx: 0, display: 'flex', flexWrap: 'wrap' },
    header3: { fontSize: 14, fontWeight: 'normal' },
    note: { mt: '-10px', fontSize: 12 },
    list: { pl: 0 },
    listItem: {
        mb: '10px',
        outline: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        py: 0,
        px: '10px',
        alignItems: 'center',
        height: 40,
        bgcolor: 'white.main',
        color: 'black.main'
    },
    cautionContainer: { bgcolor: '#b00' },
    developContainer: { bgcolor: '#aa0' }
};
