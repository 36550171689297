import React, { useState } from 'react';
import debounce from 'lodash.debounce';
import { MomentLandingLeft } from '../Share/MomentLandingLeft';
import { MomentLandingRight } from '../Share/MomentLandingRight';

import { SnapLandingLeft } from '../Snap/SnapLandingLeft';
import { SnapLandingRight } from '../Snap/SnapLandingRight';
import { MenuModal } from '../MenuModal';

import { PHOTOBOOTH_HIDDEN, MOMENTS_HIDDEN, CELEBRATION_ENABLED } from '../../../environment';
import { Box } from '@mui/material';
import { momentsStyle } from './Moments.style';
import { MomentsNavigation } from '../MomentsNavigation';

export const Moments = () => {
    const [scrollHeight, setScrollHeight] = useState(0);
    const [momentLandingLeftTop, setMomentLandingLeftTop] = useState(0);
    const handleScroll = e => {
        if (!e.target) {
            return;
        }
        const winScroll = e.target.scrollTop;
        setScrollHeight(winScroll);
    };

    const [section, setSection] = useState(() => (PHOTOBOOTH_HIDDEN ? 'Share' : 'Snap'));

    const showNavigationMenu = !PHOTOBOOTH_HIDDEN && !MOMENTS_HIDDEN;
    return CELEBRATION_ENABLED ? (
        <Box
            onScroll={debounce(handleScroll, 100, {
                leading: true,
                maxWait: 100,
                trailing: true
            })}
            sx={momentsStyle.container}>
            {showNavigationMenu && (
                <MenuModal
                    currentSection={section}
                    goToSnap={() => setSection('Snap')}
                    goToMoments={() => setSection('Share')}
                />
            )}
            {section === 'Share' ? (
                <>
                    <MomentLandingLeft scrollHeight={scrollHeight} top={momentLandingLeftTop}>
                        {showNavigationMenu && <MomentsNavigation section={section} onSectionChange={setSection} />}
                    </MomentLandingLeft>
                    <MomentLandingRight setMomentLandingLeftTop={setMomentLandingLeftTop} />
                </>
            ) : (
                <>
                    <SnapLandingLeft scrollHeight={scrollHeight} top={momentLandingLeftTop}>
                        {showNavigationMenu && <MomentsNavigation section={section} onSectionChange={setSection} />}
                    </SnapLandingLeft>
                    <SnapLandingRight setMomentLandingLeftTop={setMomentLandingLeftTop} />
                </>
            )}
        </Box>
    ) : null;
};
