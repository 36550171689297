import { headerLg } from '../constants/styles';

export const tokenStyles = {
    heading: {
        fontFamily: 'ClientHeadingFont',
        fontWeight: 'fontWeight.heading',
        textTransform: theme => theme.typography.textCase.heading
    },
    body: {
        fontFamily: 'ClientBodyFont',
        fontWeight: 'fontWeight.body',
        textTransform: theme => theme.typography.textCase.body
    }
};

export const momentsSharedStyles = {
    container: top => theme => ({
        background: theme => theme.palette.primaryGradient.main,
        display: 'flow-root',
        position: 'absolute',
        width: '50%',
        height: { xs: 'inherit', xl: `calc(100% - ${headerLg}px)` },
        top: top,
        pointerEvents: 'none',
        [theme.breakpoints.down('xl')]: {
            height: 'auto',
            pb: '30px',
            width: '100%',
            position: 'inherit',
            mt: '-18px'
        }
    }),
    bottomContainer: theme => ({
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
        margin: '0 auto',
        mt: `calc(30vh - ${headerLg}px)`,
        justifyContent: 'center',
        [theme.breakpoints.down('xl')]: {
            p: '20px',
            display: 'inherit',
            width: 'auto',
            mt: '12px'
        }
    }),
    sectionImg: {
        width: 64,
        height: 64,
        color: 'white.main'
    },
    headline: {
        ...tokenStyles.heading,
        fontStyle: 'normal',
        fontSize: { xs: 'typeSize.80', md: 'typeSize.90' },
        lineHeight: theme => ({ xs: theme.typography.lineHeight[80], md: theme.typography.lineHeight[90] }),
        mt: { xs: '16px', md: '20px' },
        color: 'white.main'
    },
    body: {
        ...tokenStyles.body,
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: 'white.main',
        mt: { xs: '16px', md: '20px' }
    }
};
