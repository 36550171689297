import { tokenStyles } from '../../../../Utils/styles/global.style';

export const videoTileStyles = {
    tile: {
        position: 'relative',
        color: 'white',
        height: { xl: '100%' }
    },
    lightBox: {
        position: 'absolute',
        opacity: 0.3,
        width: 125,
        height: 125,
        filter: 'blur(0.85px)'
    },
    lightGroup: mt => theme => ({
        position: 'relative',
        pt: { xs: '14vh', xl: '80px' },
        ml: '17%',
        mt: { xs: `${mt}px`, xl: '15%' },
        width: 390,
        height: 550,
        [theme.breakpoints.down('670px')]: {
            pt: 0
        },
        [theme.breakpoints.values.xl]: {
            m: '0 auto'
        }
    }),
    previewVideo: mt => theme => ({
        mt: `${mt}px`,
        ml: { xs: 'auto', xl: '21%' },
        position: 'inherit',
        [theme.breakpoints.down('xl')]: {
            mr: 'auto',
            textAlign: 'center'
        },
        '& video': {
            width: 'auto',
            height: 440,
            [theme.breakpoints.down('md')]: {
                height: 380
            }
        }
    }),
    heading: theme => ({
        display: 'flex',
        mx: { xs: 'unset', lg: '25%', xl: '15%' },
        [theme.breakpoints.down('xl')]: {
            width: '100%'
        },
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center'
        }
    }),
    headingText: {
        ...tokenStyles.heading,
        fontStyle: 'normal',
        fontSize: 'typeSize.60',
        lineHeight: theme => theme.typography.lineHeight[60],
        width: { xs: 245, sm: 310, md: 380 },
        color: 'white.main'
    },
    number: {
        fontStyle: 'normal',
        fontWeight: 'fontWeight.countdown',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[60],
        color: 'white.main',
        width: { xs: 40, lg: 60 }
    },
    modalLink: {
        ...tokenStyles.body,
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: 'primary.main',
        mt: '10px',
        ml: '-8px'
    },
    arrowIcon: {
        height: 24,
        width: 24
    }
};
