import { tokenStyles } from '../../../Utils/styles/global.style';

export const menuModalStyles = {
    Menu: {
        position: { xs: 'relative', xl: 'absolute' },
        top: { xs: 32, xl: '3.5%' },
        right: { xs: 20, xl: '4.7%' },
        color: 'white.main',
        pointerEvents: 'initial',
        zIndex: 999
    },
    menuBars: {
        height: 24,
        weight: 24
    },
    MenuIcon: {
        position: 'absolute',
        top: -8,
        right: { xs: 15, md: 0 },
        cursor: 'pointer'
    },
    MenuModalComponent: theme => ({
        position: 'absolute',
        width: '100vw',
        bgcolor: 'rgba(21, 21, 21, 0.8)',
        backdropFilter: 'blur(25px)',
        color: 'white.main',
        display: 'flow-root',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        pt: '3.5%',
        [theme.breakpoints.down('xl')]: {
            overflowY: 'scroll',
            overflowX: 'hidden'
        }
    }),
    MenuContentHolder: {
        margin: 'auto',
        pt: '5vh',
        width: '100%',
        maxWidth: 770,
        display: 'grid',
        gap: '32px',
        color: 'white.main',
        textAlign: { xs: 'left', md: 'center' },
        fontSize: { xs: 'typeSize.50', md: 'typeSize.60' },
        lineHeight: theme => ({ xs: theme.typography.lineHeight[50], md: theme.typography.lineHeight[60] })
    },
    titleSection: {
        px: '30px'
    },
    modalDescription: {
        ...tokenStyles.body,
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40],
        my: '8px'
    },
    MenuButtonHolder: theme => ({
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        textAlign: 'left',
        gap: '24px',
        mt: { xs: 0, md: '24px' },
        [theme.breakpoints.down('xl')]: {
            gridTemplateColumns: '1fr',
            p: '0 30px'
        }
    }),
    MenuButton: isSelected => ({
        p: { xs: '8px 24px', xl: '40px' },
        borderRadius: theme => theme.borderRadius.xl,
        color: 'white.main',
        bgcolor: isSelected ? 'primary.main' : 'rgba(255,255,255,0.08)',
        boxShadow: isSelected ? theme => theme.dropShadow.dropShadow_glow : 'none',
        fontSize: 'typeSize.60',
        lineHeight: theme => theme.typography.lineHeight[60],
        cursor: 'pointer',
        '&:hover': {
            bgcolor: 'darkButton.main'
        }
    }),
    menuButtonDescription: {
        ...tokenStyles.body,
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        mt: '8px'
    },
    MenuOr: {
        display: { xs: 'none', xl: 'grid' },
        gridTemplateColumns: 'auto min-content auto',
        gap: '16px',
        mt: '12px',
        color: 'rgba(255, 255, 255, 0.24)',
        '&>div': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '1px'
        }
    },
    line: { height: 1, backgroundColor: 'rgba(255, 255, 255, 0.24)', width: '100%' },
    MenuQR: {
        color: 'white.main',
        display: { xs: 'none', xl: 'inline-grid' },
        alignItems: 'center',
        gridTemplateColumns: '75px auto',
        gap: '24px',
        textAlign: 'left'
    },
    qrCode: { width: '100%' },
    qrTitle: {
        ...tokenStyles.heading,
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        mb: '8px'
    },
    qrDescription: {
        ...tokenStyles.body,
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40]
    },
    closeButton: {
        position: 'relative',
        top: '4.3%',
        left: '40%',
        p: 1.5
    },
    MenuModalClass: {
        width: '100%',
        outline: 'none'
    },
    svgIcon: { height: 64, width: 64, color: 'white.main' }
};
