import { useOktaAuth } from '@okta/okta-react';
import { AssignmentInd } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { loginCallbackWithErrorStyles } from './LoginCallbackWithError.style';

export const LoginCallbackWithError = () => {
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslation();
    const [error, setError] = useState(null);

    const handleLogout = () => {
        oktaAuth.signOut('/');
    };

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                await oktaAuth.handleLoginRedirect();
            } catch (loginRedirectError) {
                setError(loginRedirectError);
            }
        };
        handleRedirect();
    }, [oktaAuth]);

    if (!error) return null;

    const errorMessage = !error ? t('login.error_message') : error.errorSummary || error.message;

    return (
        <Box sx={loginCallbackWithErrorStyles.container}>
            <AssignmentInd htmlColor='white' sx={loginCallbackWithErrorStyles.assignmentInd} />
            <Box component='span' sx={loginCallbackWithErrorStyles.errorMessage}>
                {errorMessage}
            </Box>
            <Button variant='contained' color='secondary' onClick={handleLogout}>
                {t('login.retry_button')}
            </Button>
        </Box>
    );
};
