// Breakpoints
export const mobileBreakpointSm = 321; //px
export const mobileBreakpoint = 481; //px
export const tabletBreakpoint = 769; //px
export const landscapeTabletBreakpoint = 1025; //px
export const desktopLargeBreakpoint = 1550; //px

// Elements
export const headerSm = 60; //px
export const headerLg = 72; //px
