export const momentLandingRightStyles = {
    sidebar: theme => ({
        [theme.breakpoints.down('xl')]: {
            display: 'none'
        }
    }),
    sidebarTextGroup: {
        transform: 'rotate(-90deg)',
        color: 'white.main',
        opacity: 0.5,
        position: 'absolute',
        fontStyle: 'normal',
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40],
        letterSpacing: '4.5px',
        textTransform: 'uppercase',
        zIndex: 999,
        height: 10,
        right: { xs: 40, xl: 80 },
        bottom: 140
    },
    sidebarText: {
        color: 'white.main',
        opacity: 0.5,
        position: 'absolute',
        fontStyle: 'normal',
        fontSize: 'typeSize.40',
        lineHeight: theme => theme.typography.lineHeight[40],
        letterSpacing: '4.5px',
        textTransform: 'uppercase',
        zIndex: 999,
        ml: '20px',
        width: 200,
        pointerEvents: 'none'
    },
    buttonGroup: {
        position: 'absolute',
        zIndex: 999,
        bottom: '50vh',
        right: { xs: 15, xl: 60 }
    },
    verticalStroke: {
        position: 'absolute',
        zIndex: 999,
        transform: 'rotate(-270deg)',
        transformOrigin: 'top right',
        opacity: 0.7,
        mt: '7px'
    },
    modal: {
        bgcolor: 'white.main',
        position: 'absolute',
        right: '-100vw',
        left: 0,
        top: 0,
        bottom: 0,
        opacity: 0.8,
        overflow: 'visible'
    },
    content: theme => ({
        background: '#151515',
        overflowY: 'scroll',
        overflowX: 'hidden',
        [theme.breakpoints.down('xl')]: {
            overflow: 'hidden'
        }
    }),
    buttonColor: {
        color: 'rgba(255, 255, 255, 0.15)',
        '&.Mui-selected': {
            color: 'primary.main'
        }
    },
    buttonSize: {
        width: 10,
        height: 10
    },
    videoTiles: {
        minHeight: '100%',
        width: { xl: '50%' },
        position: { xl: 'relative' },
        left: { xl: 'calc(50vw)' },
        mb: { xs: '64px', xl: 0 }
    },
    footer: theme => ({
        position: { xl: 'fixed' },
        bottom: { xl: 0 },
        width: '100vw',
        bgcolor: 'black.main',
        opacity: 0.9,
        [theme.breakpoints.down('lg')]: {
            display: 'block'
        },
        [`@media (max-height: ${theme.breakpoints.values.lg})`]: {
            display: 'none'
        }
    })
};
