import React from 'react';
import AdminAuth from '../AdminAuth';
import AdminHeader from '../AdminHeader';
import { useDetectLogout } from '../../../Hooks/useDetectLogout';
import { Box } from '@mui/material';
import { adminWrapperStyles } from './AdminWrapper.style';

const Admin = ({ location }) => {
    const { loggingOut } = useDetectLogout();

    if (loggingOut) {
        return null;
    }

    return (
        <Box sx={adminWrapperStyles.wrapper}>
            <AdminHeader />
            <AdminAuth location={location} />
        </Box>
    );
};

export default Admin;
