import React, { useState } from 'react';

// Dependencies
import { Box, Button, IconButton, Modal, SvgIcon, useMediaQuery, useTheme } from '@mui/material';

// Components
import { useTranslation } from 'react-i18next';
import { SnapModal } from '../SnapModal';

// Variables
import { NarrowFooter, Footer } from '../../../Header/Header';

// Assets
import { ReactComponent as Close } from '../../../../Assets/Icons/close.svg';
import { ReactComponent as Camera } from '../../../../Assets/Icons/camera.svg';
import videoSrc from '../../../../Assets/Dynamic/snapVideo.mp4';
import { snapLandingRightStyles } from './SnapLandingRight.style';

export const SnapLandingRight = () => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('xl'));
    const FooterEl = matches ? Footer : NarrowFooter;

    return (
        <Box sx={snapLandingRightStyles.content}>
            <Box sx={snapLandingRightStyles.videoTiles}>
                <Box>
                    <Box sx={snapLandingRightStyles.previewVideo}>
                        <video loop preload='auto' muted autoPlay playsInline>
                            <source src={videoSrc} type='video/mp4' />
                            <track src='' kind='captions' label='english_captions' />
                        </video>
                    </Box>
                    <Button
                        variant='contained'
                        sx={snapLandingRightStyles.snapButton}
                        startIcon={
                            <SvgIcon
                                component={Camera}
                                viewBox='0 0 32 32'
                                sx={snapLandingRightStyles.icon}
                                alt={t('snap.title')}
                            />
                        }
                        onClick={() => setOpen(true)}>
                        {t('snap.take_snap_label')}
                    </Button>
                </Box>
                <Modal open={open}>
                    <>
                        <SnapModal />
                        <IconButton sx={snapLandingRightStyles.closeButton} onClick={() => setOpen(false)}>
                            <Close />
                        </IconButton>
                    </>
                </Modal>
            </Box>
            <Box sx={snapLandingRightStyles.footer}>
                <FooterEl />
            </Box>
        </Box>
    );
};
