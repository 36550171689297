import React, { memo, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Security, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { ThemeProvider } from '@mui/material/styles';
import WebGLApp from '../Containers/App/WebGLApp';
import Admin from './Admin/AdminWrapper';
import Logout from './Auth/Logout';
import { LoginCallbackWithError } from './Auth/LoginCallbackWithError';
import { LoginPage } from './Auth/Login';
import { oktaConfig, CALLBACK_PATH } from '../oktaConfig';
import Header from './Header/Header';
import { Moments } from './Moments/Moments';
import { CELEBRATION_ENABLED } from '../environment';
import muiTheme from '../Utils/styles/muiTheme';
import { Box } from '@mui/material';
import { rootStyles } from './Root.style';
import { useOktaAuth } from '@okta/okta-react';
import { LandingPage } from './Landing/LandingPage';
import { useWindowDimensions } from '../Hooks/useWindowDimensions';
import { ReactionsPage } from './Reactions/ReactionsPage';

const authClient = new OktaAuth(oktaConfig);
const momentPath = '/celebration';
const webGLPath = '/';

const Root = () => {
    const history = useHistory();
    const showLoginPage = async () => {
        history.push('/login');
    };

    // In the new version of Okta, in order to support token auto renew, we need to have the service running in the
    // background.
    // https://github.com/okta/okta-auth-js#running-as-a-service
    useEffect(() => {
        authClient.start();

        return () => {
            authClient.stop();
        };
    }, []);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return (
        <ThemeProvider theme={muiTheme}>
            <Security oktaAuth={authClient} onAuthRequired={showLoginPage} restoreOriginalUri={restoreOriginalUri}>
                <HeaderContainer />
                <LandingContainer />
            </Security>
        </ThemeProvider>
    );
};

const HeaderContainer = () => {
    const { authState } = useOktaAuth();

    return (
        <Switch>
            <Route path={momentPath} component={null} />
            <Route path='/login' component={Header} />
            {authState?.isAuthenticated && <Route path='*' component={Header} />}
        </Switch>
    );
};

const LandingContainer = () => {
    const { height } = useWindowDimensions();
    const { authState } = useOktaAuth();
    const isLoading = authState === null;
    const location = useLocation();

    const isMomentPath = location.pathname === momentPath;
    const isRootPath = location.pathname === webGLPath;
    const showHeader = !isMomentPath && !(isRootPath && !authState?.isAuthenticated);

    return (
        <Box sx={rootStyles.container(showHeader, isLoading, height)}>
            <RouterSwitch isAuthenticated={!!authState?.isAuthenticated} />
        </Box>
    );
};

const RouterSwitch = memo(({ isAuthenticated = false }) => (
    <Switch>
        {isAuthenticated ? (
            <Route path={'/'} exact component={() => <WebGLApp projectName='LaunchPartyWebGL' />} />
        ) : (
            <Route path={'/'} exact component={LandingPage} />
        )}
        <Route path={CALLBACK_PATH} component={LoginCallbackWithError} />
        <Route path='/login' component={LoginPage} />
        <SecureRoute path='/admin' component={Admin} />
        <SecureRoute path='/reactions' component={ReactionsPage} />
        {CELEBRATION_ENABLED && <SecureRoute path={momentPath} component={Moments} />}
        <Route path='/logout' component={Logout} />
        <Redirect path='*' to={webGLPath} />
    </Switch>
));

RouterSwitch.displayName = 'RouterSwitch';

export default Root;
