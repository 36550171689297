import { Box } from '@mui/material';
import { labelStyles } from './Label.style';

const Label = ({ title, children, htmlFor }) => (
    <Box component='label' htmlFor={htmlFor} sx={labelStyles.label}>
        {title}
        {children}
    </Box>
);

export default Label;
