import React, { useRef, useState, useEffect, useCallback } from 'react';

// Dependencies
import { Box, Button, SvgIcon } from '@mui/material';
import mergeImages from 'merge-images';

// Components
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

// SVG elements
import { ReactComponent as Download } from '../../../../Assets/Icons/cloud_download.svg';
import { ReactComponent as Camera } from '../../../../Assets/Icons/camera.svg';
import { ReactComponent as NYSEMainLogo } from '../../../../Assets/Icons/NYSEMainLogo.svg';

// Sound FX
import SFX from '../../../../Assets/Sounds/Countdown-Snap-Full.mp3';

// Dynamic Images
import PhotoFrame from '../../../../Assets/Dynamic/photo_frame.png';
import DisplayFrame from '../../../../Assets/Dynamic/display_frame.png';
import Confetti from '../../../../Assets/Dynamic/confetti_animation_repeat.gif';
import { snapModalStyles } from './SnapModal.style';

// Analytics
import { useUnityAnalytics } from '../../../../Hooks/useUnityAnalytics';

// Video Variables
window.constraints = {
    audio: false,
    video: { width: 640, height: 480 }
};

// https://mui.com/material-ui/react-dialog/#alerts
const CameraDeniedPopup = props => {
    const { t } = useTranslation();
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle id='alert-dialog-title'>{t('snap.modal_camera_denied_title')}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>
                    {t('snap.modal_camera_denied_message')}
                    <ul>
                        <li>{t('snap.modal_camera_denied_suggestion_1')}</li>
                        <li>{t('snap.modal_camera_denied_suggestion_2')}</li>
                    </ul>
                    {t('snap.modal_camera_denied_message_end')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} autoFocus>
                    {t('snap.modal_camera_denied_close_button')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export const SnapModal = () => {
    const countSFXRef = useRef();
    const videoRef = useRef(null);
    const videoStream = useRef(false);
    const photoRef = useRef(null);
    const { recordSnapShotTaken } = useUnityAnalytics();

    const { t } = useTranslation();

    // Video Streaming Functions
    const [streamStart, setStreamStart] = useState(false);

    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const startStream = async () => {
        const preMediaRequestTimestamp = Date.now();
        try {
            const stream = await navigator.mediaDevices.getUserMedia(window.constraints);
            videoStream.current = stream;
            if (videoRef.current) {
                videoRef.current.srcObject = stream;
            }
            setStreamStart(true);
        } catch (e) {
            const postMediaRequestTimestamp = Date.now();
            if (e.name === 'OverconstrainedError') {
                const v = window.constraints.video;
                console.error(`The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.`);
            } else if (e.name === 'NotAllowedError') {
                console.error(
                    'Permissions have not been granted to use your camera and microphone, you need to allow the page access to your devices in order for the demo to work.'
                );
                // Checking if the user has denied camera access in the past
                // Following logic in here: https://www.damirscorner.com/blog/posts/20170901-DetectingCameraPermissionDialogInBrowser.html
                if (postMediaRequestTimestamp - preMediaRequestTimestamp < 1000) {
                    setIsPopupOpen(true);
                }
            }
            console.error(`getUserMedia error: ${e.name}`, e);
        }
    };
    const startVideo = e => {
        if (videoStream.current) stopVideo();
        startStream(e);
        videoRef.current?.play();
    };
    const stopVideo = () => {
        setStreamStart(false);
        if (videoStream.current) {
            videoStream.current.getTracks().forEach(t => t.stop());
        }
        videoStream.current = null;
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    // Main Timer Function
    const [countDownTimer, setCountDownTimer] = useState(4);

    // Main state/holder for merged Base64 image
    const [FramePic, setFramePic] = useState(null);
    const [shareFile, setShareFile] = useState(null);

    // WebGL & Photo Merging Functions
    const startPhoto = () => {
        recordSnapShotTaken();
        setCountDownTimer(3);
        countSFXRef.current.play();
    };

    const capturePhoto = useCallback(() => {
        clearPhoto();
        const video = videoRef.current;
        const canvas = photoRef.current;
        // Change canvas draw depending on Portrate/Landscape
        if (video.videoWidth > video.videoHeight) {
            canvas.width = video.videoHeight;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, (video.videoHeight - video.videoWidth) / 2, 0, video.videoWidth, video.videoHeight);
        } else {
            canvas.width = video.videoWidth;
            canvas.height = video.videoWidth;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, (video.videoWidth - video.videoHeight) / 2, video.videoWidth, video.videoHeight);
        }
    }, []);

    const takePhoto = useCallback(() => {
        if (!videoStream) return;
        capturePhoto();
        const canvas = document.getElementById('canvas');
        canvas.toBlob(blob => {
            const offsetX = (1481 - canvas.width) / 2;
            mergeImages([{ src: URL.createObjectURL(blob), x: offsetX, y: 150 }, { src: PhotoFrame }], {
                crossOrigin: true
            }).then(b64 => {
                console.log(
                    new File([b64], `NYSE_Bell.png`, {
                        type: 'image/png',
                        lastModified: new Date().getTime()
                    })
                );
                setFramePic(b64);
                fetch(b64)
                    .then(res => res.blob())
                    .then(blob => {
                        setShareFile(
                            new File([blob], `NYSE_Bell.png`, {
                                type: 'image/png',
                                lastModified: new Date().getTime()
                            })
                        );
                    });
            });
        }, 'image/png');
    }, [capturePhoto]);

    useEffect(() => {
        countDownTimer < 4 && console.log(countDownTimer);
        if (countDownTimer <= -4 || countDownTimer >= 4) return;
        if (countDownTimer === 0) {
            videoRef.current?.pause();
            takePhoto();
        }
        const interval = setInterval(() => setCountDownTimer(prevCounter => prevCounter - 1), 900);
        return () => clearInterval(interval);
    }, [countDownTimer, takePhoto]);

    const clearPhoto = () => {
        const photo = photoRef.current;
        const ctx = photo.getContext('2d');
        ctx.clearRect(0, 0, photo.width, photo.height);
    };

    const retakePhoto = () => {
        videoRef.current?.play();
        setCountDownTimer(4);
    };

    // Photo Download
    const downloadImage = async () => {
        if (
            navigator.canShare &&
            navigator.canShare({ files: [shareFile] }) &&
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        ) {
            try {
                await navigator.share({
                    title: t('snap.modal_share_title'),
                    text: t('snap.modal_share_text'),
                    files: [shareFile]
                });
            } catch (err) {
                console.error('Share failed:', err.message);
            }
        } else {
            const link = document.createElement('a');
            link.download = 'NYSE_Bell.png';
            link.href = FramePic;
            link.click();
        }
    };

    useEffect(() => stopVideo, []);

    return (
        <Box sx={snapModalStyles.container}>
            <audio ref={countSFXRef} src={SFX} />
            <Box>
                <Box
                    sx={
                        snapModalStyles.countDownTimer <= -3
                            ? snapModalStyles.mainHolderSmall
                            : snapModalStyles.mainHolder
                    }>
                    <Box
                        sx={
                            snapModalStyles.countDownTimer <= -3
                                ? snapModalStyles.invisible
                                : snapModalStyles.snapCanvas
                        }>
                        <Box sx={snapModalStyles.videoHolder}>
                            {!streamStart && <NYSEMainLogo />}
                            <video ref={videoRef} playsInline />
                            {countDownTimer < 4 && countDownTimer > 0 && (
                                <Box sx={snapModalStyles.count}>{countDownTimer}</Box>
                            )}
                            {countDownTimer === 0 && <Box sx={snapModalStyles.flash} />}
                        </Box>
                        <Box component='img' sx={snapModalStyles.displayFrame} src={DisplayFrame} alt='' />
                        {countDownTimer <= -1 && (
                            <Box sx={snapModalStyles.confettiHolder}>
                                <img src={Confetti} alt={`${t('snap.modal_confetti_alt')} 1`} />
                                <img src={Confetti} alt={`${t('snap.modal_confetti_alt')} 2`} />
                            </Box>
                        )}
                    </Box>
                    {countDownTimer <= -3 && (
                        <Box
                            component='img'
                            sx={snapModalStyles.renderedImg}
                            src={FramePic}
                            alt={t('snap.modal_camera_capture_alt')}
                        />
                    )}
                </Box>
                <Box sx={snapModalStyles.buttonHolder}>
                    {!streamStart && (
                        <Button
                            sx={snapModalStyles.snapButton}
                            onClick={() => startVideo()}
                            variant='contained'
                            startIcon={
                                <SvgIcon
                                    component={Camera}
                                    viewBox='0 0 32 32'
                                    alt={t('snap.title')}
                                    sx={snapModalStyles.icons}
                                />
                            }>
                            {t('snap.modal_enable_camera_button')}
                        </Button>
                    )}
                    {streamStart && countDownTimer === 4 && (
                        <Button
                            sx={snapModalStyles.snapButton}
                            onClick={() => startPhoto()}
                            variant='contained'
                            startIcon={
                                <SvgIcon
                                    component={Camera}
                                    viewBox='0 0 32 32'
                                    alt={t('snap.title')}
                                    sx={snapModalStyles.icons}
                                />
                            }>
                            {t('snap.modal_take_snap_button')}
                        </Button>
                    )}
                    {countDownTimer <= -3 && (
                        <>
                            <Button
                                sx={snapModalStyles.snapButtonInline}
                                onClick={() => retakePhoto()}
                                variant='contained'
                                startIcon={
                                    <SvgIcon
                                        component={Camera}
                                        viewBox='0 0 32 32'
                                        alt={t('snap.title')}
                                        sx={snapModalStyles.icons}
                                    />
                                }>
                                {t('snap.modal_retake_button')}
                            </Button>
                            <Button
                                sx={snapModalStyles.snapButtonInline}
                                onClick={downloadImage}
                                variant='contained'
                                startIcon={
                                    <SvgIcon viewBox='0 0 24 24' component={Download} sx={snapModalStyles.icons} />
                                }>
                                {t('snap.modal_download_button')}
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
            <Box component='canvas' sx={snapModalStyles.canvas} id='canvas' ref={photoRef} width='640' height='480' />
            <CameraDeniedPopup open={isPopupOpen} handleClose={handleClosePopup} />
        </Box>
    );
};
