import {
    Box,
    CircularProgress,
    Paper,
    Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useOktaAuth } from '@okta/okta-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SERVER_ENDPOINT } from '../../../environment';
import { ReactComponent as EmojiClap } from '../../../Assets/Icons/emoji-clap.svg';
import { ReactComponent as EmojiHeart } from '../../../Assets/Icons/emoji-heart.svg';
import { ReactComponent as EmojiLaugh } from '../../../Assets/Icons/emoji-laugh.svg';
import { ReactComponent as EmojiSmile } from '../../../Assets/Icons/emoji-smile.svg';
import { ReactComponent as EmojiYes } from '../../../Assets/Icons/emoji-yes.svg';
import { ReactComponent as EmojiNo } from '../../../Assets/Icons/emoji-no.svg';
import { reactionPagestyle } from './ReactionsPage.style';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const generateRowData = ({ name, sectionData, totalData }, isTotalRow = false) => {
    const dataToUse = isTotalRow ? totalData : sectionData;
    const aggregatedEmojiData = Object.entries(dataToUse).reduce(
        (data, [k, v]) => {
            const key = parseInt(k, 10);
            if (key <= 3) {
                data.smile += v;
            } else if (key > 3 && key <= 7) {
                data.laugh += v;
            } else if (key > 7 && key <= 11) {
                data.clap += v;
            } else if (key > 11 && key <= 15) {
                data.heart += v;
            } else if (key > 15 && key <= 19) {
                data.yes += v;
            } else if (key > 19 && key <= 23) {
                data.no += v;
            }

            return data;
        },
        { smile: 0, laugh: 0, clap: 0, heart: 0, yes: 0, no: 0 }
    );
    return { name, ...aggregatedEmojiData };
};

const emojiMap = {
    clap: <EmojiClap />,
    heart: <EmojiHeart />,
    laugh: <EmojiLaugh />,
    smile: <EmojiSmile />,
    yes: <EmojiYes />,
    no: <EmojiNo />
};

export const ReactionsPage = () => {
    const { authState } = useOktaAuth();
    const history = useHistory();
    const [reactionsError, setReactionsError] = useState(null);
    const [reactionData, setReactionData] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isRecordLoading, setIsRecordLoading] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const mutableAccessToken = useRef(authState.accessToken.accessToken);

    useEffect(() => {
        mutableAccessToken.current = authState.accessToken.accessToken;
    }, [authState.accessToken.accessToken]);

    const getReactionGroupData = useCallback(() => {
        // clear cache unless on the admin API page
        fetch(`${SERVER_ENDPOINT}/reaction-groups`, {
            headers: {
                authorization: `Bearer ${mutableAccessToken.current}`
            }
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result.error) {
                        if (result.statusCode === 403) {
                            history.push('/login');
                        } else {
                            setReactionsError(result);
                        }
                    } else {
                        setReactionData(result);
                    }
                },
                error => {
                    setReactionsError(error);
                }
            )
            .finally(() => setIsLoading(false));
    }, [history]);

    useEffect(() => {
        getReactionGroupData();
    }, [getReactionGroupData]);

    const handleRecordReactionCount = async () => {
        setIsRecordLoading(true);
        try {
            const response = await fetch(`${SERVER_ENDPOINT}/reaction-groups`, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${authState.accessToken.accessToken}`
                }
            });

            if (response.status !== 204) {
                const parsedResponse = await response.json();
                if (parsedResponse.error) {
                    throw parsedResponse.error;
                }
            }
            setSnackbarMessage('Successfully recorded latest reaction count');
            getReactionGroupData();
        } catch (error) {
            setSnackbarMessage(`Error while recording reaction count ${error}`);
        } finally {
            setIsRecordLoading(false);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarMessage('');
    };

    const rows = useMemo(() => reactionData?.sections?.map(section => generateRowData(section)).reverse(), [
        reactionData
    ]);
    const totalRow = useMemo(
        () =>
            reactionData?.sections && reactionData.sections.length > 0
                ? generateRowData(reactionData.sections[reactionData.sections.length - 1], true)
                : null,
        [reactionData]
    );

    if (isLoading) {
        return null;
    }

    return (
        <Box sx={reactionPagestyle.container}>
            {reactionsError ? (
                <Box>Error: {reactionsError.message}</Box>
            ) : (
                <TableContainer component={Paper} sx={reactionPagestyle.tableContainer}>
                    <Box sx={reactionPagestyle.buttonContainer}>
                        <LoadingButton
                            variant='contained'
                            startIcon={<RadioButtonCheckedIcon />}
                            loading={isRecordLoading}
                            loadingIndicator={
                                <CircularProgress sx={reactionPagestyle.recordButtonLoadingIndicator} size={24} />
                            }
                            sx={reactionPagestyle.recordButton}
                            onClick={handleRecordReactionCount}>
                            Record reaction count
                        </LoadingButton>
                    </Box>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.smile}</TableCell>
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.laugh}</TableCell>
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.clap}</TableCell>
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.heart}</TableCell>
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.yes}</TableCell>
                                <TableCell sx={reactionPagestyle.tableCell}>{emojiMap.no}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {totalRow && (
                                <TableRow key='Totals' sx={reactionPagestyle.tableRow}>
                                    <TableCell component='th' scope='row'>
                                        Totals
                                    </TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.smile}</TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.laugh}</TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.clap}</TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.heart}</TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.yes}</TableCell>
                                    <TableCell sx={reactionPagestyle.tableCell}>{totalRow.no}</TableCell>
                                </TableRow>
                            )}
                            {rows &&
                                rows.map(row => (
                                    <TableRow key={row.name} sx={reactionPagestyle.tableRow}>
                                        <TableCell component='th' scope='row'>
                                            {row.name}
                                        </TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.smile}</TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.laugh}</TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.clap}</TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.heart}</TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.yes}</TableCell>
                                        <TableCell sx={reactionPagestyle.tableCell}>{row.no}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                    {rows && rows.length === 0 && (
                        <Box sx={reactionPagestyle.emptyStateText}>Reaction count has not been recorded yet.</Box>
                    )}
                </TableContainer>
            )}
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                open={!!snackbarMessage}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                message={snackbarMessage}
            />
        </Box>
    );
};
