import { Box, SvgIcon } from '@mui/material';
import { ReactComponent as UnityLogo } from '../../../Assets/Icons/UnityLogoWhite.svg';
import { ReactComponent as NYSELogoSmall } from '../../../Assets/Icons/NYSELogoSmall.svg';
import { landingHeaderStyle } from './LandingPageHeader.style';

export const LandingPageHeader = () => {
    return (
        <Box sx={landingHeaderStyle.container}>
            <Box sx={landingHeaderStyle.content}>
                <SvgIcon component={NYSELogoSmall} viewBox='0 0 72 29' sx={landingHeaderStyle.icon} />
                <Box sx={landingHeaderStyle.divider} />
                <SvgIcon component={UnityLogo} viewBox='0 0 113 40' sx={landingHeaderStyle.icon} />
            </Box>
        </Box>
    );
};
