import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { momentsNavigationStyle } from './MomentsNavigation.style';

export const MomentsNavigation = ({ section, onSectionChange }) => {
    const { t } = useTranslation();

    return (
        <Box sx={momentsNavigationStyle.container}>
            <Box onClick={() => onSectionChange('Snap')} sx={momentsNavigationStyle.buttonHolder(section === 'Snap')}>
                <div>{t('celebration.navigator_01')}</div>
                <Box sx={momentsNavigationStyle.lineHolder}>
                    <Box sx={momentsNavigationStyle.line(section === 'Snap')} />
                </Box>
                <Box sx={momentsNavigationStyle.title}>{t('celebration.navigator_snap')}</Box>
            </Box>
            <Box onClick={() => onSectionChange('Share')} sx={momentsNavigationStyle.buttonHolder(section === 'Share')}>
                <div>{t('celebration.navigator_02')}</div>
                <Box sx={momentsNavigationStyle.lineHolder}>
                    <Box sx={momentsNavigationStyle.line(section === 'Share')} />
                </Box>
                <Box sx={momentsNavigationStyle.title}>{t('celebration.navigator_moments')}</Box>
            </Box>
        </Box>
    );
};
