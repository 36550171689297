import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClientLogoMoment } from '../../Assets/moments_logo.svg';
import { ReactComponent as UnityLogo } from '../../Assets/Icons/UnityLogoWhite.svg';
import { ReactComponent as NYSELogoSmall } from '../../Assets/Icons/NYSELogoSmall.svg';
import { ReactComponent as CookieConsentIcon } from '../../Assets/Icons/cookie_consent.svg';
import { Box, SvgIcon, useMediaQuery, useTheme } from '@mui/material';
import { headerStyles } from './Header.style';
import { isMobileOnly } from 'react-device-detect';

const CURRENT_YEAR = new Date().getFullYear();

const HeaderWrapper = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return matches || isMobileOnly ? <SmallerHeader /> : <Header />;
};

const Header = () => (
    <Box sx={headerStyles.header}>
        <UnityNYSELogos />
        <HeaderRight />
    </Box>
);

export const Footer = () => (
    <Box sx={headerStyles.header}>
        <UnityNYSELogos />
        <LegalInfo />
    </Box>
);

export const SmallerHeader = () => (
    <Box sx={headerStyles.mobileHeader}>
        <UnityNYSELogos />
        <Box onClick={() => window.OneTrust.ToggleInfoDisplay()} sx={headerStyles.mobileCookieConsent}>
            <SvgIcon component={CookieConsentIcon} viewBox='0 0 48 48' sx={headerStyles.cookieConsentIconSmall} />
        </Box>
    </Box>
);

export const NarrowFooter = () => {
    const { t } = useTranslation();

    return (
        <Box sx={headerStyles.headerCenter}>
            <UnityNYSELogos />
            <Box sx={headerStyles.legalCenter}>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal'
                    sx={headerStyles.link}>
                    {t('header.legal_label')}
                </Box>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal/privacy-policy'
                    sx={headerStyles.link}>
                    {t('header.privacy_policy_label')}
                </Box>
            </Box>
            <Box component='span' sx={headerStyles.copyrightNarrow}>
                {t('header.copyright_label', { currentYear: CURRENT_YEAR })}
            </Box>
        </Box>
    );
};

export const UnityNYSELogos = () => (
    <Box sx={headerStyles.headerLeft}>
        <SvgIcon component={NYSELogoSmall} viewBox='0 0 72 35' sx={headerStyles.svgIcon} />
        <Box sx={headerStyles.NYSEVerticalBar} />
        <SvgIcon component={UnityLogo} viewBox='0 0 113 40' sx={headerStyles.svgIcon} />
    </Box>
);

export const LegalInfo = () => {
    const { t } = useTranslation();

    return (
        <Box>
            <Box sx={headerStyles.legal}>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal'
                    sx={headerStyles.link}>
                    {t('header.legal_label')}
                </Box>
                <Box
                    component='a'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://unity3d.com/legal/privacy-policy'
                    sx={headerStyles.link}>
                    {t('header.privacy_policy_label')}
                </Box>
            </Box>
            <Box sx={headerStyles.copyright}>{t('header.copyright_label', { currentYear: CURRENT_YEAR })}</Box>
        </Box>
    );
};

export const MomentsHeader = () => {
    const { t } = useTranslation();

    return (
        <Box sx={headerStyles.headerMiddle}>
            <Box component={ClientLogoMoment} sx={headerStyles.clientLogo} />
            <Box component='span' sx={headerStyles.momentsVerticalBar} />
            <Box sx={headerStyles.listingDay}>{t('header.listing_day_label')}</Box>
        </Box>
    );
};

export const HeaderRight = () => (
    <Box sx={headerStyles.headerRightRoot}>
        <LegalInfo />
        <CookieConsent />
    </Box>
);
export const CookieConsent = () => (
    <Box sx={headerStyles.cookieConsentParent} onClick={() => window.OneTrust.ToggleInfoDisplay()}>
        <SvgIcon component={CookieConsentIcon} viewBox='0 0 48 48' sx={headerStyles.cookieConsentIcon} />
    </Box>
);

export default HeaderWrapper;
