export const landingHeaderStyle = {
    container: {
        justifyContent: 'center',
        width: '100%',
        zIndex: 1
    },
    content: { display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '32px', p: '40px' },
    icon: { height: 32, width: 88 },
    divider: {
        height: 32,
        width: '1px',
        bgcolor: 'white.main'
    }
};
