import { tokenStyles } from '../../Utils/styles/global.style';

export const vimeoFallbackStyles = {
    container: {
        width: 420,
        height: 240,
        bgcolor: 'white.main',
        borderRadius: theme => theme.borderRadius.m,
        p: '24px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxSizing: 'border-box'
    },
    fallbackTitleText: isTitle => ({
        color: '#262626',
        lineHeight: theme => theme.typography.lineHeight[60],
        textAlign: 'center',
        fontSize: isTitle ? 'typeSize.50' : 'typeSize.40',
        ...(isTitle ? { fontWeight: 'bold' } : {})
    }),
    copyContentIcon: {
        color: 'primary.main'
    },
    viewOnVimeoButton: {
        ...tokenStyles.body,
        color: 'white.main',
        bgcolor: 'primary.main',
        width: '100%',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        fontSize: 'typeSize.50',
        alignItems: 'center',
        textTransform: 'none',
        letterSpacing: '0.21px'
    },
    passwordContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(206, 213, 221, 0.4)',
        borderRadius: theme => theme.borderRadius.m,
        p: '8px 14px',
        m: '14px 0'
    },
    passwordText: theme => ({
        color: 'primary.main',
        fontSize: 'typeSize.50',
        letterSpacing: '0',
        lineHeight: theme => theme.typography.lineHeight[60],
        textAlign: 'center',
        borderBottom: `1px dashed ${theme.palette.primary.main}`
    }),
    yourPasswordText: {
        ...tokenStyles.heading,
        alignSelf: 'center',
        color: '#262626',
        fontSize: 'typeSize.40',
        letterSpacing: '0',
        mr: '10px'
    }
};
