import { tokenStyles } from '../../Utils/styles/global.style';

export const webGLContainerStyles = {
    wrapper: { display: 'flex', flexDirection: 'column', position: 'relative', height: '100%', width: '100%' },
    container: theme => ({
        aspectRatio: '16 / 9',
        width: 'auto',
        height: 'inherit',
        maxHeight: 'calc(100vw * (9 / 16))',
        maxWidth: '100%',
        zIndex: '100',
        margin: '0 auto',
        position: 'inherit',
        bgcolor: 'black.main',
        [theme.breakpoints.down('xl')]: {
            '@media (orientation: landscape) and (hover: none) and (pointer: coarse) ': {
                height: '100%',
                width: 'auto'
            }
        }
    }),
    canvas: {
        width: '100%',
        height: '100%',
        webkitUserSelect: 'none' /* Chrome all / Safari all */,
        MozUserSelect: 'none' /* Firefox all */,
        MsUserSelect: 'none' /* IE 10+ */,
        OUserSelect: 'none',
        userSelect: 'none'
    },
    rotationPrompt: theme => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
                display: 'flex',
                flexFlow: 'column wrap',
                alignItems: 'center',
                background: 'rgba(255, 255, 255, 0.04)',
                mt: '-3px',
                pb: '24px'
            }
        }
    }),
    rotationImage: {
        p: '16px'
    },
    rotationMessage: {
        ...tokenStyles.body,
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        width: '72%',
        color: 'white.main',
        height: 48,
        textAlign: 'center'
    },
    footer: theme => ({
        display: 'none',
        [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                bottom: 0,
                left: '50%',
                transform: 'translate(-50%, 0)',
                width: '100%'
            }
        }
    })
};
