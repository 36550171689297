export const momentsStyles = {
    downloadButton: {
        width: 'max-content',
        p: '12px 16px',
        mt: '24px'
    },
    buttons: {
        display: 'grid',
        pointerEvents: 'auto',
        gridTemplateColumns: '1fr 1fr'
    },
    stickyNavigationBar: {
        position: 'absolute',
        background: theme => theme.palette.primaryGradient.main,
        width: '100%',
        zIndex: '1',
        top: '0px',
        display: { xl: 'none' }
    },
    stickyContainer: {
        display: { xs: 'flex', xl: 'none' },
        justifyContent: 'space-between',
        alignItems: 'center',
        p: '12px 18px'
    },
    stickyHeading: {
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[50],
        color: 'white.main'
    },
    stickyButton: {
        p: '8px 12px',
        fontStyle: 'normal',
        fontSize: 'typeSize.50',
        lineHeight: theme => theme.typography.lineHeight[40],
        color: 'white.main',
        bgcolor: 'black.main',
        '&:hover': {
            opacity: 0.64
        }
    },
    downloadIcon: { height: 24, width: 24, color: 'primary.main' },
    stickyDownloadIcon: { height: 20, width: 20, color: 'white.main' }
};
