import { createTheme } from '@mui/material';
import {
    desktopLargeBreakpoint,
    landscapeTabletBreakpoint,
    mobileBreakpoint,
    mobileBreakpointSm,
    tabletBreakpoint
} from '../constants/styles';
import { mapNestedTokens, mapShadows } from './utils';
import { DESIGN_TOKENS } from '../../environment';

const {
    borderRadius,
    buttons,
    colors,
    dropShadow,
    typeSize,
    fontWeight,
    gradients,
    lineHeight,
    textCase,
    typeFace
} = DESIGN_TOKENS;

const tokens = {
    palette: {
        ...mapNestedTokens({ tokens: colors, isColor: true }),
        ...mapNestedTokens({ tokens: buttons, isColor: true, suffix: 'Button' }),
        ...mapNestedTokens({ tokens: gradients, isColor: true, suffix: 'Gradient' })
    },
    typography: {
        fontFamily: typeFace.UI.value,
        headingFont: 'ClientHeadingFont',
        bodyFont: 'ClientBodyFont',
        buttonFont: 'ClientButtonFont',
        typeSize: mapNestedTokens({ tokens: typeSize, isPxValue: true }),
        lineHeight: mapNestedTokens({ tokens: lineHeight, isPxValue: true }),
        textCase: mapNestedTokens({ tokens: textCase }),
        fontWeight: mapNestedTokens({ tokens: fontWeight })
    },
    borderRadius: mapNestedTokens({ tokens: borderRadius, isPxValue: true }),
    dropShadow: mapShadows(dropShadow)
};

export default createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: mobileBreakpointSm,
            md: mobileBreakpoint,
            lg: tabletBreakpoint,
            xl: landscapeTabletBreakpoint,
            xxl: desktopLargeBreakpoint
        }
    },
    ...tokens,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: tokens.typography.fontWeight.button,
                    fontSize: tokens.typography.typeSize[50],
                    fontStyle: 'normal',
                    fontFamily: tokens.typography.buttonFont,
                    lineHeight: tokens.typography.lineHeight[50],
                    textTransform: tokens.typography.textCase.button
                },
                contained: {
                    padding: '12px 16px',
                    borderRadius: tokens.borderRadius.m
                },
                containedPrimary: {
                    color: tokens.palette.lightButton.main,
                    backgroundColor: tokens.palette.darkButton.main,
                    '&:hover': {
                        backgroundColor: tokens.palette.darkButtonHover.main
                    },
                    '&:active': {
                        backgroundColor: tokens.palette.darkButtonPressed.main
                    }
                },
                containedSecondary: {
                    color: tokens.palette.darkButton.main,
                    backgroundColor: tokens.palette.lightButton.main,
                    '&:hover': {
                        backgroundColor: tokens.palette.lightButtonHover.main
                    },
                    '&:active': {
                        backgroundColor: tokens.palette.lightButtonPressed.main
                    }
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    fontFamily: tokens.typography.fontFamily
                }
            }
        }
    }
});
