import React from 'react';
import { Box } from '@mui/material';

export const FileDownloader = props => {
    const { url, downloadRef } = props;
    return (
        <Box component='a' href={url} ref={downloadRef} sx={{ display: 'none' }}>
            Download
        </Box>
    );
};
