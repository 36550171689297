import React from 'react';
import Label from '../Label';
import { Box } from '@mui/material';
import { inputStyles } from './Input.style';

const Input = ({ name, type, placeholder, onChange, value, checked, error, children, label, onKeyPress }) => (
    <>
        <Label title={label} htmlFor={name}>
            <Box
                component='input'
                id={name}
                name={name}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value || ''}
                checked={type === 'checkbox' && checked}
                sx={inputStyles.input(error)}
                onKeyPress={onKeyPress}
            />
            {children}
        </Label>
        {error && <p>{error}</p>}
    </>
);

export default Input;
