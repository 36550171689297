import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, SvgIcon } from '@mui/material';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { MomentsHeader } from '../../../Header/Header';
import { ReactComponent as Moments } from '../../../../Assets/Icons/moments.svg';
import { ReactComponent as Download } from '../../../../Assets/Icons/cloud_download.svg';
import { FileDownloader } from '../../FileDownloader';
import { landscapeTabletBreakpoint } from '../../../../Utils/constants/styles';
import { ReactComponent as Checkmark } from '../../../../Assets/Icons/checkmark.svg';
import { SERVER_ENDPOINT } from '../../../../environment';
import { momentsSharedStyles } from '../../../../Utils/styles/global.style';
import { momentsStyles } from './MomentLandingLeft.style';
import { useUnityAnalytics } from '../../../../Hooks/useUnityAnalytics';
import { useWindowDimensions } from '../../../../Hooks/useWindowDimensions';

export const MomentLandingLeft = props => {
    const { authState } = useOktaAuth();
    const { recordSocialMediaDownloadAll } = useUnityAnalytics();

    const { scrollHeight, top, children } = props;
    const [visible, setVisible] = useState(false);
    const { t } = useTranslation();

    const containerTop = top < 0 ? `${top}px` : '0px';

    const windowSize = useWindowDimensions();

    const containerRef = useRef(null);
    useEffect(() => {
        if (containerRef.current == null) {
            return;
        }

        let shouldBeVisible;
        if (windowSize.width < landscapeTabletBreakpoint) {
            shouldBeVisible = scrollHeight > containerRef.current.clientHeight;
        } else {
            shouldBeVisible = windowSize.width > landscapeTabletBreakpoint;
        }
        if (visible !== shouldBeVisible) {
            setVisible(shouldBeVisible);
        }
    }, [windowSize.width, scrollHeight, visible]);

    const [downloadAllAssetsURL, setDownloadAllAssetsURL] = useState('');
    const [isDownloaded, setIsDownloaded] = useState(false);
    const fileDownloader = useRef(null);

    const downloadAllAssets = () => {
        recordSocialMediaDownloadAll();
        fetch(`${SERVER_ENDPOINT}/moments/all-moments-url`, {
            method: 'GET',
            headers: { authorization: `Bearer ${authState.accessToken.accessToken}` }
        })
            .then(response => response.json())
            .then(data => {
                setDownloadAllAssetsURL(data.momentURL);
            })
            .catch(error => console.log(error));
    };

    useEffect(() => {
        if (downloadAllAssetsURL) {
            fileDownloader.current.click();
            setIsDownloaded(true);
            setDownloadAllAssetsURL('');
        }
    }, [downloadAllAssetsURL]);

    return (
        <>
            <Box sx={momentsSharedStyles.container(containerTop)} ref={containerRef}>
                <MomentsHeader />
                <Box sx={momentsSharedStyles.bottomContainer}>
                    <SvgIcon
                        component={Moments}
                        viewBox='0 0 128 128'
                        sx={momentsSharedStyles.sectionImg}
                        alt={t('moments.title')}
                    />{' '}
                    <Box sx={momentsSharedStyles.headline}>{t('moments.title')}</Box>
                    <Box sx={momentsSharedStyles.body}>{t('moments.description')}</Box>
                    <Box sx={momentsStyles.buttons}>
                        <Button
                            variant='contained'
                            color='secondary'
                            onClick={downloadAllAssets}
                            sx={momentsStyles.downloadButton}
                            startIcon={
                                <SvgIcon
                                    viewBox={isDownloaded ? '0 0 19 14' : '0 0 24 24'}
                                    component={isDownloaded ? Checkmark : Download}
                                    sx={momentsStyles.downloadIcon}
                                />
                            }>
                            {t('moments.download_all_btn_label')}
                        </Button>
                        <FileDownloader url={downloadAllAssetsURL} downloadRef={fileDownloader} />
                    </Box>
                    {children}
                </Box>
            </Box>
            {visible && (
                <Box sx={momentsStyles.stickyNavigationBar}>
                    <Box sx={momentsStyles.stickyContainer}>
                        <Box sx={momentsStyles.stickyHeading}>{t('moments.get_moments_label')}</Box>
                        <Button
                            variant='contained'
                            onClick={downloadAllAssets}
                            sx={momentsStyles.stickyButton}
                            endIcon={
                                <SvgIcon
                                    viewBox={isDownloaded ? '0 0 19 14' : '0 0 24 24'}
                                    component={isDownloaded ? Checkmark : Download}
                                    sx={momentsStyles.stickyDownloadIcon}
                                />
                            }>
                            {t('moments.download_all_btn_label')}
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    );
};
